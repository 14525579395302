import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'JPS BO';

  constructor(
    public translateService: TranslateService,
    private router: Router,
    private authService: AuthenticationService,
    public userService: UserService
  ) {
    this.initLocalization();
  }

  ngOnInit(): void {}

  private initLocalization() {
    this.translateService.addLangs(['en', 'fr']);
    this.translateService.setDefaultLang('fr');
    this.translateService.use('fr');
    // const currentLang = localStorage.getItem('lang');
    // console.log('currentLang', currentLang);
    // if (currentLang) {
    //   this.translateService.use(currentLang);
    // } else {
    //   this.translateService.use('en');
    // }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
