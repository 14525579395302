<div style="margin: 24px">
  <h1>{{'Users_mngt' | translate}}</h1>
  <!--
  show the list of users
  -->
  <div *ngFor="let user of nonBoUsers; index as i">
    <div class="row">

      <div class="col-md-10">

        <a [routerLink]="['/user']" [queryParams]="user">
          <h3>{{i + 1}} - {{'name' | translate}}: {{user.firstName}} {{user.lastName}}</h3>
        </a>
        <p>{{'Email' | translate}} ID: {{user.username}}</p>
<!--        <p>{{'password' | translate}}: {{user.password}}</p>-->

<!--        <p>Email: {{user.email}}</p>-->
      </div>
    </div>
    <hr>
  </div>
</div>
