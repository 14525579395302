<div class="dialog-wrapper">
    <h1 class="title">{{(data?.title ? data.title : 'DIALOGS.titleInfo') | translate}}</h1>
    <p class="description">
        {{(data?.content ? data.content : 'MODALS.confirmBody') | translate}}
    </p>
    <div class="btn-container">
        <button
            mat-button
            class="sr-kit_button view-more"
            data-color="primary"
            data-size="l"
            (click)="dialogRef.close(true)"
        >
            {{'OK' | translate}}
        </button>
    </div>
</div>
