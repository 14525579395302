import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-left',
  templateUrl: './left.component.html',
  styleUrls: ['./left.component.scss']
})
export class LeftComponent {

  constructor(
    private router: Router,

  ) {
  }
  openPage(nextPage: String) {
    this.router.navigate([nextPage]).then();
  }
}
