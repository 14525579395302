<!--<div  style="margin: auto; width: 100px" >-->
<!--</div>-->
<div style="margin: 24px;">
  <h1 style="text-align: center">{{(editMode ? 'Edit' : 'Add') | translate}} {{'Topic' | translate}}</h1>

  <form [formGroup]="form">
    <p>{{form.controls['topicType'].value}} - {{form.value['_id']}}</p>
    <mat-form-field>
      <mat-label>{{'Topic_name' | translate}}</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'Topic_Description' | translate}}</mat-label>
      <input matInput formControlName="description">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{"Topic_Status" | translate}}</mat-label>
      <mat-select formControlName="status">
        <mat-option *ngFor="let status of topicStatues" [value]="status.value">
          {{status.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div style="display: flex; justify-content: center">

      <!-- cancel button-->
      <button mat-raised-button color="warn" (click)="onNoClick()">{{'Cancel' | translate}}</button>
      <span style="width: 40px"></span>
      <!-- submit button-->
      <button mat-raised-button color="primary" (click)="onSubmit()">{{'Submit' | translate}}</button>
    </div>
  </form>

</div>
