import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IUser, UserService } from './user.service';

@Injectable(/*{ providedIn: 'root' }*/)
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<IUser | null>;
  public currentUser: Observable<IUser | null>;

  constructor(private http: HttpClient, private userService: UserService) {
    let item = localStorage.getItem('currentUser');
    let user = null;

    try {
      if (item) {
        user = JSON.parse(item);
      }
    } catch (e) {
      console.log('user parse error: ', e);
    }
    this.currentUserSubject = new BehaviorSubject<IUser | null>(user);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  login(username: string, password: string) {
    console.log('login service');
    /*    const user = {
          id: 1,
          username: 'test',
          password: 'test',
          firstName: 'test',
          lastName: 'test',
          token: 'test'
        } as IUser;

        this.userService.setCurrentUser(user);
        this.currentUserSubject.next(user);*/
    return this.http
      .post<any>(`${environment.api}/auth/login-bo`, { username, password })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          console.log('result of login', user);
          if (user && user.role) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));

            this.userService.setCurrentUser(user);
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    this.userService.removeCurrentUser();
    this.currentUserSubject.next(null);
  }
}
