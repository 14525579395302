export interface ITopicData {
  _id?: string;
  // botTypeId: string;
  // botTypeName: string;
  name: string;
  description: string;
  image?: string;
  status: string; // active, inactive
  topicType: string;
}

export interface ISeriesData {
  _id?: string;
  topicId: string;
  order: number;
  name: string;
  description: string;
  image?: string;
  status: string; // active, inactive
  questions: string[];
  // topicType: string;
}

export interface IQuestionData {
  _id?: string;
  order: number;
  theme: string;
  question: string;

  choices: [
    {
      choice: string;
      isCorrect: boolean;
    }
  ];
}

export interface IBlockData {
  _id?: string;
  order: number;
  isUser: boolean;
  blockName: string;
  blockType: string; // start-block, end-block, normal-block // BlockTypes
  replyType: string; // text, multi-choices, single-choice // ReplyTypes
  message: string;
  replies: IReplyData[];
  image?: string;

  topicId: string;
  topicName: string;
  topicDescription: string;
  topicStatus: string;
  topicImage?: string;
  prevBlocks: [
    {
      prevId: string;
      prevName: string;
      _id: false;
    }
  ];
}

export interface IReplyData {
  content: string;
  nextBlockName: string;
  nextBlockId: string;
}

export class ReplyTypes {
  static readonly TEXT = 'text';
  static readonly NONE = 'none';
  static readonly MULTI_CHOICES = 'multi-choices';
  static readonly SINGLE_CHOICE = 'single-choice';
}

export class TopicStatuses {
  static readonly ACTIVE = 'active';
  static readonly INACTIVE = 'inactive';
}

export class DialogButtons {
  static readonly OK = 'button-ok';
  static readonly CANCEL = 'button-cancel';
}

export class BlockTypes {
  static readonly START = 'start-block';
  static readonly END = 'end-block';
  static readonly NORMAL = 'normal-block';
  // static readonly TEXT_ONLY = 'message';
}

export class TopicTypes {
  static readonly BOT = 'bot-topic';
  static readonly QUIZ = 'quiz-topic';
}

export class AppConst {
  static readonly VALIDATE_DATA_WITH_SERVER = false;

  static get DEBOUNCE_TIME() {
    if (AppConst.VALIDATE_DATA_WITH_SERVER) {
      return 500;
    }
    return 0;
  }
}

export interface IDialogData {
  title: string;
  message: string;
}

export interface IUser {
  _id?: string;
  firstName: string;
  lastName: string;
  email: string;
  image?: string;
  username: string;
  password: string;
  status: String;
  role: string;
  // topicProfile: string,
  department: string;
  // botProfileId: string,
  // botProfileName: string,
  // quizProfileId: string,
  // quizProfileName: string
}

export interface IUserQuiz {
  _id?: string;
  userId: IUser;
  series: any[];
}
