import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-quiz-result',
  templateUrl: './quiz-result.component.html',
  styleUrls: ['./quiz-result.component.scss'],
})
export class QuizResultComponent {
  displayedColumns: string[] = ['order', 'question', 'response', 'action'];

  seri: any = null;
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.seri = data.series[0];
    this.dataSource = new MatTableDataSource(
      this.seri.seriDataJsonString.questions
    );
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getReStyle(item: any) {
    try {
      const bgColor = !item.isUserChoice
        ? 'transparent'
        : item.isCorrect
        ? 'green'
        : 'red';
      const color = bgColor === 'transparent' ? 'black' : 'white';
      return {
        'background-color': bgColor,
        color,
      };
    } catch (error) {
      return {};
    }
  }
}
