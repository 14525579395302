<div style="display: flex">
  <button mat-raised-button color="primary"
          (click)="goBack()" style="margin-top: 20px; margin-left: 20px;">{{"Back" | translate}}
  </button>
  <div style="margin-top: 20px; margin-left: 20px;">
    <!--  <a mat-mini-fab >-->
    <mat-icon>add</mat-icon>

    {{'Click_to_add_new_question' | translate}}
    <mat-icon>save</mat-icon>
    {{'Click_to_save_question' | translate}}
  </div>
</div>
<div class="bot-block-form" style="max-width: 800px; margin: 24px; ">


  <!--  <mat-accordion>--> <!--  this is the accordion only one can open-->
  <div [formGroup]="formGroup" cdkDropList (cdkDropListDropped)="drop($event)">
    <!--    <p>{{'Series' | translate}} ID: {{formGroup.controls.seriesId.value}}</p>-->

    <div formArrayName="questions" style="margin-top: 16px"
         *ngFor="let questionCtl of questions.controls; index as questionIndex;" cdkDrag>
      <mat-expansion-panel [expanded]="true" (opened)="panelOpenStates[questionIndex] = true"
                           (closed)="panelOpenStates[questionIndex] = false">

        <mat-expansion-panel-header>
          Question {{questions.controls[questionIndex].get('order')?.value + 1}}

        </mat-expansion-panel-header>

        <div [formGroupName]="questionIndex">
          <div style="display: flex; justify-content: space-between">
            <!--            <mat-form-field class="bot-block-field small-input" style="margin-right: 24px">-->
            <!--              <mat-label>{{'Order_Number' | translate}}</mat-label>-->
            <!--              <input matInput formControlName="order" autocomplete="off">-->
            <!--            </mat-form-field>-->
            <!--            button to delete the question -->


            <mat-form-field class="bot-block-field small-input">
              <mat-label>{{'Theme' | translate}}</mat-label>
              <input matInput formControlName="theme" autocomplete="off">
            </mat-form-field>
            <button mat-mini-fab color="warn" (click)="deleteQuestion(questionIndex)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>

          <mat-form-field class="bot-block-field">
            <mat-label>{{"Question" | translate}}</mat-label>
            <textarea matInput formControlName="question" autocomplete='disabled'
                      cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="4">
            </textarea>
          </mat-form-field>

          <div formArrayName="choices">
            <ng-container *ngFor="let choice of getQuestionChoices(questionIndex).controls;
                                index as answerIndex;">
              <div [formGroupName]="answerIndex" style="display: flex;">
                <mat-checkbox color="primary" formControlName="isCorrect" style="margin-top: 10px"></mat-checkbox>
                <mat-form-field class="bot-block-field ">
                  <mat-label>{{'Answer' | translate}}</mat-label>
                  <input matInput formControlName="choice" autocomplete="off">
                </mat-form-field>
                <button mat-icon-button color="warn" style="margin-top: 10px; margin-left: 10px;"
                        (click)="deleteAnswer(questionIndex, answerIndex)">
                  <mat-icon>remove_circle_outline</mat-icon>
                </button>
              </div>

            </ng-container>

          </div>
          <!-- create a button to add the choice-->
          <div style="display: flex">
            <button mat-raised-button color="primary" (click)="addAnswer(questionIndex)"
                    style="margin: 16px auto auto;">{{'Add_answer' | translate}}
            </button>
          </div>


        </div>
      </mat-expansion-panel>
    </div>
  </div>

  <!--  add a center button to add new question-->
  <!--  <div style="display: flex">
      <button mat-raised-button color="primary" (click)="addQuestion()" style="margin: 16px auto auto;">Add Question
      </button>
    </div>-->

  <!--  <div style="display: flex">
      <button mat-raised-button color="primary" (click)="submit()" disabled="{{!isFormValid()}}"
              style="margin: 16px auto auto; width: 300px; height: 60px">{{'Submit' | translate}}
      </button>
    </div>-->
  <!--  </mat-accordion>-->
  <div
    style="z-index:5; position : fixed;display: flex; flex-direction: column; align-self : flex-end;top: 120px;
          left: 1150px; margin-bottom : 30px;">

    <a mat-fab (click)="addQuestion()">
      <mat-icon>add</mat-icon>
    </a>
    <a mat-fab style="margin-top: 14px;" (click)="submit()" [disabled]="!isFormValid()">
      <mat-icon>save</mat-icon>
    </a>

  </div>

</div>
