import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

// import { AlertService, AuthenticationService } from '../services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });
  returnUrl: any;
  loading: boolean = false;
  submitted: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private snack: MatSnackBar
  ) {
    console.log(
      'login component constructor:',
      this.userService.getCurrentUser()
    );
    /*
        this.loginForm = new FormGroup({
          email: new FormControl('', [Validators.required, Validators.email]),
          password: new FormControl('', [Validators.required])
        });
    */

    // redirect to home if already logged in
    if (this.userService.getCurrentUser()) {
      this.router
        .navigate(['/bots'])
        .then((r) => console.log('already logged in'));
    }
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  hide: boolean = true;
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    console.log('login component onSubmit', this.loginForm.value);
    this.loading = true;
    this.authenticationService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          console.log('login success');
          this.router
            .navigate(['/bots'])
            .then((r) => console.log('navigated to home'));
        },
        error: (error: any) => {
          console.log('login error');
          // this.alertService.error(error);
          this.snack.open('Login failed. Please check username and password.');

          this.loading = false;
        },
      });
  }
}
