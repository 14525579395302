import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import {
  AppConst,
  IBlockData,
  ISeriesData,
  ITopicData,
  TopicTypes,
} from '../interfaces/jps-interface';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { InfoDialogComponent } from '../ui/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';

// https://www.w3.org/wiki/CSS/Properties/color/keywords
const basicColors = [
  '#FF0000',
  '#800000',
  '#daa520',
  '#808000',
  '#48d1cc',
  '#008000',
  '#0000FF',
  '#000080',
  '#FF00FF',
  '#800080',
  '#008080',
  // '#808080',
  '#FFA500',
  '#A52A2A',
  '#d2691e',
  '#00FFFF',

  '#6495ed',
  '#dc143c',
  '#ff7f50',
  '#00008b',
  '#008b8b',
  '#b8860b',
];

@Injectable(/*{
    providedIn: 'root'
}*/)
export class DataService {
  // public serverData = true;
  private existingUsernames = ['Batman', 'Superman', 'Joker', 'Luthor'];
  blocksOfTopic: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  seriesOfTopic: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  questionsOfSeries: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  nonBoUsers: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  usersQuizSubj: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  questionsOfSeries$: Observable<any> = this.questionsOfSeries.asObservable();
  topics: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  blockDetailSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  foundBlocks: any[] = [];
  allBlocks: any[] = [];
  operationOk = false;
  colorsOfBlock: { [key: string]: string } = {};
  // allSeries: any[] = [];

  // public isCreateNew = false;

  constructor(
    private api: ApiService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog
  ) {}

  // checkIfBlockIdExists(blockId: string): Observable<boolean> {
  //   if (!blockId) {
  //     return of(false).pipe(delay(0));
  //   }
  //
  //   return of(this.existingUsernames.some((a) => a === blockId))
  //     .pipe(delay(0));
  //
  // }

  resetData() {
    this.allBlocks = [];
    this.foundBlocks = [];
  }

  getBlocksByTopic(topicId: string, callFrom: string) {
    this.spinner.show();

    return this.api
      .get(`${environment.api}/data/blocksByTopic/${topicId}`)
      .subscribe({
        next: (value: any) => {
          console.log('getAllBotBlocksByTopic list: ', value);
          this.blocksOfTopic.next(value.data);
          this.allBlocks = value.data;
          this.buildBlockColors2();
          if (this.foundBlocks.length === 0) {
            this.foundBlocks = value.data;
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();

          this.snackBar.open(
            this.translateService.instant('ST_DATA_LOAD_FAILED'),
            undefined,
            { duration: 3000 }
          );
        },
      });
  }

  buildBlockColors_() {
    for (let i = 0; i < this.allBlocks.length; i++) {
      const block = this.allBlocks[i];
      this.colorsOfBlock[block._id] = Math.floor(
        Math.random() * 16777215
      ).toString(16);
    }
    console.log('colorsOfBlock: ', this.colorsOfBlock);
  }

  buildBlockColors2() {
    for (let i = 0; i < this.allBlocks.length; i++) {
      const block = this.allBlocks[i];
      this.colorsOfBlock[block._id] = basicColors[i % basicColors.length];
    }
    console.log('colorsOfBlock: ', this.colorsOfBlock);
  }

  getAllTopics(type: string) {
    // console.log('get all topics', `${environment.api}/data/topics/xxx`);
    this.spinner.show();
    return this.api.get(`${environment.api}/data/topics/${type}`).subscribe({
      next: (value: any) => {
        console.log('getAllTopics list: ', value);
        this.topics.next(value);
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
      },
    });
  }

  createTopic(topic: ITopicData) {
    return this.api.post(`${environment.api}/data/topic`, topic).pipe();
  }

  updateTopic(topic: ITopicData) {
    // if (topic.topicType === TopicTypes.BOT) {
    return this.api.put(`${environment.api}/data/topic`, topic).pipe();
    // } else {
    //   return this.api.put(`${environment.api}/data/topicForQuiz`, topic).pipe();
    // }
  }

  createSeries(series: ISeriesData) {
    return this.api.post(`${environment.api}/data/series`, series).pipe();
  }

  updateSeries(series: ISeriesData) {
    return this.api.put(`${environment.api}/data/series`, series).pipe();
  }

  _createBlock(block: IBlockData) {
    this.spinner.show();
    this.operationOk = false;

    return this.api.post(`${environment.api}/data/block`, block).subscribe({
      next: (value: any) => {
        console.log('createBlock: ', value);
        this.getBlocksByTopic(block.topicId, 'createBlock()');
        this.spinner.hide();
        this.snackBar.open(
          this.translateService.instant('data_saved_ok'),
          undefined,
          { duration: 3000 }
        );
        this.operationOk = true;
      },
      error: (e) => {
        this.spinner.hide();
        this.operationOk = false;
        console.log('create block error', e);
        this.snackBar.open(
          this.translateService.instant('data_saved_failed') +
            ': ' +
            e.error.message,
          undefined,
          { duration: 3000 }
        );
      },
    });
  }

  createBlock(block: IBlockData) {
    return this.api.post(`${environment.api}/data/block`, block).pipe();
  }

  // await new Promise(resolve => setTimeout(resolve, 5000));
  getBlockById(blockId: string) {
    this.spinner.show();
    return this.api.get(`${environment.api}/data/block/${blockId}`).subscribe({
      next: (value: any) => {
        console.log('block detail data', value);
        this.blockDetailSubject.next(value.data);
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
      },
    });
  }

  deleteBlockById(blockId: string) {
    // this.spinner.show();
    return this.api.delete(`${environment.api}/data/block/${blockId}`).pipe();
  }

  deleteTopic(topicId: string) {
    // this.spinner.show();
    return this.api.delete(`${environment.api}/data/topic/${topicId}`);
  }

  checkBlockNameExist(blockName: string) {
    console.log('checkBlockNameExist', blockName);
    if (!blockName || blockName.length == 0) {
      return of(true);
    }
    if (AppConst.VALIDATE_DATA_WITH_SERVER) {
      return this.api
        .get(`${environment.api}/data/checkBlockName/${blockName}`)
        .pipe(
          map((value: any) => {
            console.log('checkBlockNameExist', value);
            return value.status === 'error';
          })
        );
    } else {
      const block = this.allBlocks.find((block: any) => {
        return block.blockName === blockName;
      });
      // console.log('checkBlockNameExist found', found);
      if (block) {
        return of(true);
      } else {
        return of(false);
      }
    }
  }

  checkBlockIdExist(blockId?: string) {
    if (!blockId) {
      return of(false);
    }
    const block = this.allBlocks.find((block: any) => {
      return block._id === blockId;
    });
    // console.log('checkBlockNameExist found', found);
    if (block) {
      return of(true);
    } else {
      return of(false);
    }
  }

  findBlocksByName(blockName: string) {
    // if (this.lastSearchSubscription) {
    //     console.log('cancel last search');
    //     this.lastSearchSubscription.unsubscribe();
    // }
    console.log('call findBlockByName with: ', blockName);
    blockName = blockName.toLowerCase();
    if (!blockName || blockName.length == 0) {
      console.log('empty block name', ' all blocks: ', this.allBlocks);
      return of({ data: this.allBlocks });
    }

    if (AppConst.VALIDATE_DATA_WITH_SERVER) {
      return this.api.get(
        `${environment.api}/data/findBlocksByName/${blockName}`
      );
      // .subscribe({
      //     next: (value: any) => {
      //         this.foundBlocks = [];
      //         console.log('findBlocksByName data', value.data);
      //         this.foundBlocks = value;
      //     }, error: () => {
      //         // this.foundBlocks = [];
      //         this.foundBlocks = [];
      //     },
      // });
    } else {
      const result = this.allBlocks.filter((block: any) => {
        // console.log('block.blockName: ', block.blockName);
        const name = block.blockName.toLowerCase();
        const message = block.message.toLowerCase();
        const namePos = name.search(blockName);
        const messagePos = message.search(blockName);
        console.log('namePos: ', namePos, ' messagePos: ', messagePos);
        return namePos >= 0 || messagePos >= 0;
        // return block.blockName.toLowerCase().includes(blockName)
        //   || block.message.toLowerCase().includes(blockName);
      });
      console.log('findBlockByName result: ', result);

      return of({ data: result });
    }
  }

  findBlock(name: string): any {
    console.log('findBlock: ', name);
    if (name.length === 0) {
      console.log('findBlock: return empty');

      return {
        _id: '',
        blockName: '',
      };
    }
    return this.allBlocks.find((block: any) => {
      return block.blockName === name;
    });
  }

  getSeriesByTopic(topicId: string) {
    this.spinner.show();

    return this.api
      .get(`${environment.api}/data/seriesByTopic/${topicId}`)
      .subscribe({
        next: (value: any) => {
          console.log('get Series By Topic list: ', value);
          this.seriesOfTopic.next(value.data);
          // this.allSeries = value.data;
          this.spinner.hide();
        },
        error: (e) => {
          this.spinner.hide();
          this.snackBar.open(
            this.translateService.instant('ST_DATA_LOAD_FAILED') +
              ' ' +
              e.error()
          );
        },
      });
  }

  updateQuestionsOfSeries(body: any) {
    this.spinner.show();

    return this.api
      .put(`${environment.api}/data/seriesQuestions`, body)
      .subscribe({
        next: (value: any) => {
          console.log('get Series By Topic list: ', value);
          // this.seriesOfTopic.next(value.data);
          // this.allSeries = value.data;
          // this.snackBar.open(
          //   this.translateService.instant('data_saved_ok'),
          // );
          this.spinner.hide();
          this.snackBar.open(this.translateService.instant('data_saved_ok'));
        },
        error: () => {
          this.spinner.hide();
          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              title: 'data_saved_failed',
              content: 'data_saved_failed',
            },
            disableClose: true,
          });

          dialogRef.afterClosed().subscribe((result) => {});
        },
      });
  }

  getQuestionsOfSeries(seriesId: string) {
    this.spinner.show();

    return this.api
      .get(`${environment.api}/data/seriesQuestions/${seriesId}`)
      .subscribe({
        next: (value: any) => {
          console.log('get Series By Topic list: ', value);
          this.questionsOfSeries.next(value.data);
          this.spinner.hide();
        },
        error: (e) => {
          this.spinner.hide();
          this.snackBar.open(
            this.translateService.instant('ST_DATA_LOAD_FAILED') +
              ' ' +
              e.error()
          );
        },
      });
  }

  deleteSeriesById(seriesId: string) {
    return this.api.delete(`${environment.api}/data/series/${seriesId}`).pipe();
  }

  updateBlockOrder(blocksOfTopic: IBlockData[]) {
    return this.api
      .put(`${environment.api}/data/blockOrder`, blocksOfTopic)
      .pipe();
  }

  getUserProfiles() {
    return this.api.get(`${environment.api}/data/userProfiles`).subscribe({
      next: (value: any) => {
        console.log('getUserProfiles: ', value);
        this.nonBoUsers.next(value.data);
      },
      error: (e) => {
        this.spinner.hide();
        this.snackBar.open(
          this.translateService.instant('ST_DATA_LOAD_FAILED') + ' ' + e.error()
        );
      },
    });
  }

  updateUserProfileById(body: any) {
    return this.api.put(`${environment.api}/data/userProfile`, body).subscribe({
      next: (value: any) => {
        console.log('updateUserProfileById: ', value);
        this.getUserProfiles();
        this.snackBar.open(this.translateService.instant('data_saved_ok'));
      },
      error: (e) => {
        this.spinner.hide();
        this.snackBar.open(
          this.translateService.instant('data_saved_failed') + ' ' + e.error()
        );
      },
    });
  }
  /** quiz users */
  getUsersQuiz() {
    return this.api.post(`${environment.api}quiz/users-anwsers`, {}).subscribe({
      next: (value: any) => {
        this.usersQuizSubj.next(value.data);
      },
      error: (e) => {
        this.spinner.hide();
        this.snackBar.open(
          this.translateService.instant('ST_DATA_LOAD_FAILED') + ' ' + e.error()
        );
      },
    });
  }
}
