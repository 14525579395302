<div style="margin: 24px; max-width: 1000px;">

  <h1>{{"All_quiz_profiles" | translate}}</h1>

  <!--create add button-->
  <button *ngIf="userService?.getCurrentUser()?.role === 'superadmin'" mat-raised-button color="primary"
          (click)="openAddTopicDialog()">{{"Add_topic" | translate}}
  </button>
  <div style="justify-content: center; display: flex">
    <mat-grid-list cols="1" rowHeight="100px" gutterSize="10px">
      <mat-grid-tile *ngFor="let topic of topics; index as i">
        <div style="color: #ffffff; font-size: x-large; width: 80%; margin-left: 12px">
          Profil: {{topic.name}}
        </div>
        <mat-icon fontIcon="settings" (click)="openQuizTopic(topic)" style="margin: 10px"></mat-icon>
        <mat-icon fontIcon="edit" (click)="openAddTopicDialog(topic)" style="margin: 10px"></mat-icon>
        <mat-icon fontIcon="delete" (click)="deleteTopic(topic)" [ngStyle]="{'color':  'red'}"
                  *ngIf="userService?.getCurrentUser()?.role === 'superadmin'"></mat-icon>
      </mat-grid-tile>
    </mat-grid-list>
  </div>


</div>
