import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../services/data.service';
import { IUserQuiz } from '../interfaces/jps-interface';
import { QuizResultComponent } from './quiz-result/quiz-result.component';
import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-quiz',
  templateUrl: './user-quiz.component.html',
  styleUrls: ['./user-quiz.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class UserQuizComponent {
  usersQuiz: IUserQuiz[] = [];
  columnsToDisplay: string[] = [
    'Email',
    'table_head_username',
    'table_head_name',
    'table_head_department',
    'table_head_quizRes',
  ];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: IUserQuiz | null = null;
  dataSource = new MatTableDataSource(this.usersQuiz);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  constructor(
    private api: ApiService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    public dataService: DataService
  ) {
    this.dataService.getUsersQuiz();
    this.dataService.usersQuizSubj.subscribe((value: any) => {
      if (value) {
        this.usersQuiz = value;
        this.dataSource = new MatTableDataSource(this.usersQuiz);
        this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.translateService
      .get('table_head_itemperpage')
      .subscribe((res: string) => {
        this.paginator._intl.itemsPerPageLabel = res;
        this.dataSource.paginator = this.paginator;
      });
  }
  customFilterPredicate(data: any, keywords: any): boolean {
    try {
      const { userId } = data;
      const checkEmail: boolean = userId.email
        .toUpperCase()
        .includes(keywords.toUpperCase());
      const checkUsername: boolean = userId.username
        .toUpperCase()
        .includes(keywords.toUpperCase());
      const checkFirstName: boolean = userId.firstName
        .toUpperCase()
        .includes(keywords.toUpperCase());
      const checkLastName: boolean = userId.lastName
        .toUpperCase()
        .includes(keywords.toUpperCase());
      return checkEmail || checkUsername || checkFirstName || checkLastName;
    } catch (error) {
      console.error(error);
    }
    return true;
  }
  getName(u: any): string {
    try {
      return `${u.firstName} ${u.lastName}`;
    } catch (error) {
      return '';
    }
  }

  getHeader(column: any): string {
    try {
      return this.translateService.instant(column);
    } catch (error) {
      return '';
    }
  }
  displayValue(element: any, column: any): any {
    try {
      if (column === 'Email') {
        return element.userId.email;
      }
      if (column === 'table_head_username') {
        return element.userId.username;
      } else if (column === 'table_head_name') {
        return `${element.userId.firstName} ${element.userId.lastName}`;
      } else if (column === 'table_head_department') {
        return element.userId.department;
      } else if (column === 'table_head_quizRes') {
        return `${element.series.length} ${this.translateService.instant(
          'quiz_series_dones'
        )}`;
      }
      return '';
    } catch (error) {
      return '';
    }
  }
  displayDate(d: any): any {
    try {
      return this.datePipe.transform(d, 'dd/MM/yyyy HH:mm');
    } catch (error) {
      console.error(error);
      return '';
    }
  }
  openDialog(user: any, seriId: any): void {
    try {
      this.api
        .get(
          `${environment.api}/quiz/users-anwsers/${user.userId._id}/${seriId}`
        )
        .subscribe({
          next: (value: any) => {
            this.dialog.open(QuizResultComponent, {
              height: '85%',
              width: '100%',
              data: {
                ...value.data,
              },
            });
          },
          error: (error) => {
            console.error(error);
          },
        });
    } catch (error) {
      console.error(error);
    }
  }
  downloadCsv(): void {
    try {
      this.api.downloadCsv(`${environment.api}quiz/users-quiz-csv`).subscribe({
        next: (value: any) => {
          const url = window.URL.createObjectURL(new Blob([value]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `users-quiz_${new Date().getTime()}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
        error: (error) => {
          console.error(error);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }
}
