<!-- https://codelogy.net/pavel.salauyou/new-in-angular:-conditional-built-in-control-flow-73fe-453c -->

<div style="margin: 24px">
  <div
    style="
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
    "
  >
    <h1 style="margin: 0 !important">{{ "Users_quiz" | translate }}</h1>
    <button mat-raised-button color="primary" (click)="downloadCsv()">
      {{ "download_csv_report" | translate }}
    </button>
  </div>
  <mat-form-field>
    <mat-label>{{ "search_by_kw" | translate }}</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="username, email ..."
      #input
    />
  </mat-form-field>

  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    multiTemplateDataRows
    class="mat-elevation-z8"
  >
    <ng-container
      *ngFor="let column of columnsToDisplay"
      matColumnDef="{{ column }}"
    >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ getHeader(column) }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ displayValue(element, column) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          aria-label="expand row"
          (click)="
            expandedElement = expandedElement === element ? null : element;
            $event.stopPropagation()
          "
        >
          <mat-icon *ngIf="expandedElement === element; else tmpKeydow"
            >keyboard_arrow_up</mat-icon
          >

          <ng-template #tmpKeydow
            ><mat-icon>keyboard_arrow_down</mat-icon></ng-template
          >
        </button>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="columnsToDisplayWithExpand.length"
      >
        <div
          class="example-element-detail"
          [@detailExpand]="
            element == expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <ng-container *ngFor="let seri of element.series">
            <section style="width: 100%; background-color: rgba(0, 0, 0, 0.08)">
              <div class="example-label">{{ seri.seriId.name }}</div>
              <div class="example-button-row">
                <button mat-button>
                  {{ "done_at" | translate }} {{ displayDate(seri.lastUpdate) }}
                </button>
                <button mat-button color="primary">
                  {{ seri.score }} {{ "score" | translate }}
                </button>
                <button
                  mat-raised-button
                  color="accent"
                  (click)="openDialog(element, seri.seriId._id)"
                >
                  {{ "view" | translate }}
                </button>
              </div>
            </section>
            <mat-divider></mat-divider>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplayWithExpand"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="example-detail-row"
    ></tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[25, 100]"
    aria-label="Select page"
  ></mat-paginator>
</div>
