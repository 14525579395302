import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../../services/data.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent {

  userForm = this.fb.group({
    _id: new FormControl(null),
    firstName: new FormControl(null),
    lastName: new FormControl(null),
    email: new FormControl(null, Validators.email),
    role: new FormControl(null),
    username: new FormControl(null, Validators.email),
    password: new FormControl(null, Validators.minLength(6)),
    status: new FormControl(null),
    topicProfile: new FormControl(null),
    // botProfileId: new FormControl(null),
    // quizProfileId: new FormControl(null),
    // botProfileName: new FormControl(null),
    // quizProfileName: new FormControl(null),
    department: new FormControl(null),
  });

  botProfileId: any;
  quizProfileId: any;

  constructor(
    private translateService: TranslateService,
    public dataService: DataService,
    private fb: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private _location: Location,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,
    private userService: UserService,
  ) {
    if (!this.userService.isSuperAdmin()) {
      this.userForm.controls['department'].disable();
    }
    this.activeRoute.queryParams.subscribe(params => {
      this.userForm.patchValue(params);
      console.log('user form: ', this.userForm.value);
      // this.userForm.controls['botProfileId'].disable();
      // this.userForm.controls['quizProfileId'].disable();
      // this.userForm.controls['botProfileName'].disable();
      // this.userForm.controls['quizProfileName'].disable();
      // this.userForm.controls['_id'].disable();
    });
  }

  updateProfile() {
    if (this.userForm.invalid) return;
    this.dataService.updateUserProfileById(this.userForm.value);

  }

  goBack() {
    this._location.back();

  }
}
