<div class="dialog-wrapper">
  <h1 class="title">{{(data?.title ? data.title : 'default_confirm_dialog_tile') | translate}}</h1>
  <p class="description">
    {{(data?.message ? data.message : 'default_confirm_dialog_body') | translate}}
  </p>
  <div class="btn-container">
    <button
      mat-button
      class="sr-kit_button view-more"
      data-color="primary"
      data-size="l"
      (click)="dialogRef.close(true)"
    >
      {{'default_confirm_dialog_yes' | translate}}
    </button>
    <button
      mat-button
      class="sr-kit_button view-more"
      data-color="secondary"
      data-size="l"
      (click)="dialogRef.close()"
    >
      {{'default_confirm_dialog_no' | translate}}
    </button>
  </div>
</div>
