<h1 mat-dialog-title>{{ "table_head_quizRes" | translate }}</h1>
<ng-container *ngIf="seri">
  <div mat-dialog-content>
    <strong>{{ seri.seriId.name }}</strong> - {{ seri.seriId.description
    }}<mat-divider style="margin-top: 10px; margin-bottom: 10px"></mat-divider>
    <span style="font-style: italic"
      >{{ "quiz_res_explications" | translate }}
    </span>
  </div>

  <div
    style="
      width: 90%;
      margin: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    "
  >
    <table mat-table [dataSource]="dataSource" class="mat-mdc-table">
      <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef>No.</th>
        <td mat-cell *matCellDef="let element">{{ element.order + 1 }}</td>
      </ng-container>

      <ng-container matColumnDef="question">
        <th mat-header-cell *matHeaderCellDef>Question</th>
        <td mat-cell *matCellDef="let element">{{ element.question }}</td>
      </ng-container>

      <ng-container matColumnDef="response">
        <th mat-header-cell *matHeaderCellDef>response</th>
        <td
          mat-cell
          *matCellDef="let element"
          style="
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          "
        >
          <div
            *ngFor="let item of element.choices"
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <button
              mat-flat-button
              style="margin-bottom: 5px; text-align: start; padding: 5px"
              [ngStyle]="getReStyle(item)"
            >
              {{ item.choice }}
            </button>
            <button
              *ngIf="!item.isUserChoice && item.isCorrect"
              style="margin-bottom: 5px;margin-left: 20px"
              mat-icon-button
              color="primary"
              aria-label="Example icon button with a home icon"
            >
              <mat-icon>done</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-stroked-button
            style="color: green"
            *ngIf="element.isCorrect; else tmpKo"
          >
            Ok
          </button>
          <ng-template #tmpKo
            ><button mat-stroked-button style="color: red">
              Ko
            </button></ng-template
          >
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      style="margin-top: 20px"
      [pageSizeOptions]="[5, 10, 20]"
      aria-label="Select page"
    ></mat-paginator>
  </div>
</ng-container>

<div mat-dialog-actions style="margin: 20px">
  <button mat-button mat-dialog-close>{{ "Cancel" | translate }}</button>
</div>
