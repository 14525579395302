import {Component, OnInit} from '@angular/core';
import {DataService} from "../services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from '@angular/common';
import {TopicDialogComponent} from "../topic-dialog/topic-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {BlockTypes, DialogButtons, IBlockData, ReplyTypes} from "../interfaces/jps-interface";
import {InfoDialogComponent} from "../ui/info-dialog/info-dialog.component";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-all-bot-blocks',
  templateUrl: './all-bot-blocks.component.html',
  styleUrls: ['./all-bot-blocks.component.scss']
})
export class AllBotBlocksComponent implements OnInit {
  topic: any;
  topicDocId = '';
  blocksOfTopic: IBlockData[] = [];

  constructor(
    public dataService: DataService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private _location: Location,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {
    // this.activeRoute.params.subscribe((params: any) => {
    //     console.log('params of all bot blocks', params);
    //
    // });
    this.dataService.resetData();
    this.activeRoute.queryParams.subscribe((params: any) => {
      // console.log('query params of all bot blocks', params);
      this.topic = `ID: ${params.topicId}, Name: ${params.topicName}`;
      this.topicDocId = params.topicId;
      this.dataService.getBlocksByTopic(this.topicDocId, 'all bots');
    });
  }


  ngOnInit() {
    this.dataService.blocksOfTopic.subscribe((value: any) => {
      console.log('blocks of topic from server: ', value);
      if (value) {
        this.blocksOfTopic = value;
        this.resetBlockOrder();

      }
    });
  }

  openBlockDetail(id?: string) {
    //navigate to block detail page
    if (!id) {
      return;
    }
    this.router.navigate(['block/detail'], {
      queryParams: {blockId: id, topicId: this.topicDocId},
    }).then();
  }

  createNewBlock() {
    if (!this.topicDocId) {
      console.log('Topic id is empty');
      return;
    }

    this.dataService.getBlocksByTopic(this.topicDocId, 'when createNewBlock');
    this.router.navigate(['block/new'],
      {
        queryParams: {topicId: this.topicDocId, order: this.blocksOfTopic.length},
      }
    ).then();
  }

  deleteBlock(block: any) {
    if (!block) {
      return;
    }
    const prevBlocks = block.prevBlocks ?? [];
    let data: any;
    if (prevBlocks.length > 0) {
      data = {
        'title': 'Cannot_delete_block',
        'content': 'This blocks is used in the following block: ['
          + prevBlocks.map((prevBlock: any) => prevBlock.prevName).join(', ') + ']'
      };

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: data,
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    } else {
      data = {
        'title': 'Delete_block',
        'content': 'Confirm_delete_block',
      };

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: data,
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(result => {
        // call delete api
        // if (result.event === DialogButtons.OK) {

        this.spinner.show().then();
        this.dataService.deleteBlockById(block._id).subscribe({
          next: (value: any) => {
            this.snackBar.open(this.translateService.instant('Delete_data_successfully'));
            this.dataService.getBlocksByTopic(this.topicDocId, 'all bots');
            this.spinner.hide().then();
          },
          error: (error: any) => {
            this.snackBar.open(this.translateService.instant('Fail_to_delete_data'));

            this.spinner.hide().then();

          },
        });
        // window.location.reload();
        // }
      });
    }

  }

  goBack() {
    this._location.back();
  }

  resetBlockOrder() {
    for (let i = 0; i < this.blocksOfTopic.length; i++) {
      this.blocksOfTopic[i].order = i;
    }
    this.dataService.updateBlockOrder(this.blocksOfTopic).subscribe({
      next: (value: any) => {
        // this.snackBar.open(this.translateService.instant('Update_data_successfully'));
        console.log('update block order successfully');
        // reload
        // this.dataService.getBlocksByTopic(this.topicDocId, 'after resetBlockOrder');
      },
      error: (e: any) => {
        this.snackBar.open(this.translateService.instant('Fail to update block order') +  + ' ' + e.error());
      },
    });
  }

  drop($event: CdkDragDrop<IBlockData>) {
    moveItemInArray(this.blocksOfTopic, $event.previousIndex, $event.currentIndex);
    this.resetBlockOrder();

  }

  isBlockValid(block: IBlockData) {
    if (block.blockType === BlockTypes.END ) {
      if (block.replies.length == 0) return false;
      const prevBlock = block.prevBlocks[0];
      if (!prevBlock) {
        return false;
      }
      return true;
    }
    if (!block.replies) {
      return false;
    }

    if (block.replies.length == 0) {
      return false;
    }
    if (block.replies && block.replies.length > 0) {
      for (let reply of block.replies) {
        if (!reply.nextBlockId) {
          return false;
        }
      }
    }
    return true;
  }

  getBlockColor(blockId: any, block?: any) {
    if (block?.blockType === BlockTypes.END) {
      return null;
    }
    if (!blockId) {
      return '#ffffff';
    }
    const blockColor = this.dataService.colorsOfBlock[blockId];
    // console.log('blockColor: ', blockColor);
    return this.dataService.colorsOfBlock[blockId];
  }

  protected readonly ReplyTypes = ReplyTypes;
}
