import {Injectable} from "@angular/core";

export interface IUser {
  id: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  token: string;
  topicProfile: string;
  role: string;
}

@Injectable(/*{
    providedIn: 'root'
}*/)
export class UserService {


  setCurrentUser(user: IUser) {
    try {
      localStorage.setItem('currentUser', JSON.stringify(user));
      console.log('set current user', user);
    } catch (e) {
      console.log('error setting user', e);
    }
  }

  getCurrentUser(): IUser | null {
    const user = localStorage.getItem('currentUser');
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
    // return JSON.parse(localStorage.getItem('currentUser') || '{}');
  }

  removeCurrentUser() {
    localStorage.removeItem('currentUser');
  }

  isSuperAdmin() {
    const user = this.getCurrentUser();
    return user?.role === 'superadmin';
  }
}
