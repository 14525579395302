<!--<div *ngFor="let type of types" style="margin: 0; width: 100%; justify-content: center; display: flex">-->
<!--  {{type}}-->
<!--</div>-->

<div style="justify-content: center; display: flex">
  <mat-grid-list cols="1" rowHeight="100px" gutterSize="10px">
    <mat-grid-tile *ngFor="let type of types"
    (click)="openType(type)">
      <div style="color: #e74c3c; font-size: x-large; width: 100%; margin-left: 24px">
        {{type.name}}
      </div>
    </mat-grid-tile>
  </mat-grid-list>

</div>
