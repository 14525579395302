import { Component } from '@angular/core';
import { Router} from "@angular/router";

@Component({
  selector: 'app-bot-types',
  templateUrl: './bot-types.component.html',
  styleUrls: ['./bot-types.component.scss']
})
export class BotTypesComponent {
  types = [
    {
      id: "technical",
      name: "Technical Engineering",
    },
    {
      id: "sales",
      name: "Sales",
    }
  ]

  constructor(
    private router: Router,
    // private activeRoute: ActivatedRoute,

  ) {

  }
  openType(type: any) {
    this.router.navigate(['/topics'], {
      queryParams: {type: type.id, name: type.name},
    }).then();
  }
}
