<!--<h3 style="margin: 24px;">{{"Topic" | translate}} {{topic}}</h3>-->
<!--create back button-->
<button mat-raised-button color="primary"
        (click)="goBack()" style="margin-left: 20px; margin-right: 20px; margin-top: 20px">{{"Back" | translate}}
</button>
<!--create button to add block-->
<button mat-raised-button color="primary" style="margin-top: 20px;"
        (click)="createNewBlock()">{{"Add_series" | translate}}

</button>
<h3 style="margin-left: 20px; ">{{"Topic" | translate}} {{topic}}</h3>

<div style="justify-content: center; display: flex; max-width: 1400px;">
  <mat-grid-list cols="1" rowHeight="100px" gutterSize="10px"  cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-grid-tile *ngFor="let series of seriesOfTopic " cdkDrag>
      <div style="font-size: x-large; width: 100%; margin-left: 24px">
      {{series.name}}
      </div>
<!--        {{"Series" | translate}}:  -->
      <mat-icon fontIcon="question_answer" (click)="openBlockDetail(series._id)" style="margin-right: 6px"></mat-icon>
      <mat-icon fontIcon="edit" (click)="createNewBlock(series)" style="margin: 10px"></mat-icon>
      <mat-icon fontIcon="delete" [ngStyle]="{'color':'red'}"
                (click)="deleteBlock(series)"
                style="margin-right: 16px"></mat-icon>
    </mat-grid-tile>
  </mat-grid-list>
</div>


<!--https://fonts.google.com/icons?icon.set=Material+Icons&selected=Material+Icons+Outlined:question_answer:&icon.query=question -->
