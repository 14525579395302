<div style="height: 50px"></div>
<div class="menu-tile" (click)="openPage('/bots')">
  {{ ("Create_bots" | translate).toUpperCase() }}
</div>

<div class="menu-tile" (click)="openPage('/quizzes')">
  {{ ("Create_quizzes" | translate).toUpperCase() }}
</div>
<!-- 
<div class="menu-tile" (click)="openPage('/users-management')">
  {{('Users_mngt' | translate).toUpperCase() }}
</div>
-->
<!--
<div class="menu-tile" (click)="openPage('/users')">
  Add Users
</div>-->
<div class="menu-tile" (click)="openPage('/users-quiz')">
  {{ ("Users_quiz" | translate).toUpperCase() }}
</div>
