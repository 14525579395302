import {
    AbstractControl, AsyncValidator,
    AsyncValidatorFn,
    ValidationErrors, ValidatorFn,
} from '@angular/forms';
import {catchError, debounce, debounceTime, distinctUntilChanged, first, Observable, of, switchMap, tap} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataService} from "../services/data.service";
import {Injectable} from "@angular/core";
import {AppConst} from "../interfaces/jps-interface";
@Injectable({providedIn: 'root'})
export class BlockIdValidator {
    // https://github.com/angular/angular/issues/13200
    static uniqueId(dataService: DataService, initValue: string): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            //https://stackoverflow.com/questions/36919011/how-to-add-debounce-time-to-an-async-validator-in-angular-2
            //https://stackoverflow.com/questions/36919011/how-to-add-debounce-time-to-an-async-validator-in-angular-2
            // a bug of Angular Reactive Forms
            // https://stackoverflow.com/questions/70708799/control-with-async-validator-changes-its-state-to-pending-when-calling-updateval
            // control.markAsTouched();
            if (!control.valueChanges || control.pristine) {
                console.log('uniqueId Async Validator running in case: ', '!control.valueChanges || control.pristine')
                return of(null);
            }
            return control.valueChanges
                .pipe(
                    debounceTime(AppConst.DEBOUNCE_TIME),
                    distinctUntilChanged(),
                    switchMap((value) => {
                        if (value === initValue) {
                            return of(false);
                        }
                        return dataService.checkBlockNameExist(value)
                    }),
                    tap((exist) => control.markAsTouched()),
                    map(
                        (exist) => (exist ? {'exist': true} : null)),
                    first(),

                    catchError(() => of(null))
                );

            // return dataService.checkIfBlockIdExists(control.value)
            //   .pipe(
            //     map((res) => {
            //       return res ? {uniqueId: true} : null;
            //     })
            //   );

        };
    }

    static blockExist(dataService: DataService): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            //https://stackoverflow.com/questions/36919011/how-to-add-debounce-time-to-an-async-validator-in-angular-2

            // a bug of Angular Reactive Forms
            // https://stackoverflow.com/questions/70708799/control-with-async-validator-changes-its-state-to-pending-when-calling-updateval
            // control.markAsTouched();

            if (!control.valueChanges || control.pristine) {
                console.log('blockExist Async Validator running in case: ', '!control.valueChanges || control.pristine')
                return of(null);
            }

            return control.valueChanges
                .pipe(
                    debounceTime(AppConst.DEBOUNCE_TIME),
                    distinctUntilChanged(),
                    switchMap((value) => dataService.checkBlockNameExist(value)),
                    tap((exist) => control.markAsTouched()),
                    map((exist) => (exist === true ? null : {'exist': true} )),
                    first() // important to make observable finite
                );

            // return dataService.checkIfBlockIdExists(control.value)
            //   .pipe(
            //     map((res) => {
            //       return res ? {uniqueId: true} : null;
            //     })
            //   );

        };
    }

    static blockIdExist(dataService: DataService): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            console.log('run block id validator');
            return dataService.checkBlockIdExist(control.value) ? null : {'not-exist': true} ;
        };
    }
}

//
// @Injectable({providedIn: 'root'})
// export class UniqueBlockIdValidator implements AsyncValidator {
//   constructor(private dataService: DataService) {
//   }
//
//   validate(
//     control: AbstractControl
//   ): Observable<ValidationErrors | null> {
//     return this.dataService.checkIfBlockIdExists(control.value)
//       .pipe(
//           map(
//             (res) => {
//                 return res ? {blockIdExists: true} : null;
//             }
//           )
//     );
//   }
// }


export function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const forbidden = nameRe.test(control.value);
        return forbidden ? {forbiddenName: {value: control.value}} : null;
    };
}
