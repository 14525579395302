import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError, map, throwError, timeout } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface IResponse {
  httpStatus: number;
  code: number;
  codeDescription: string;
  message: string;
  errors: any[];
}

@Injectable(/*{
    providedIn: 'root'
}*/)
export class ApiService {
  static readonly APP_ERROR_CODE: number = 202;

  constructor(private http: HttpClient, private snack: MatSnackBar) {}

  errorHandler(error: any) {
    if (error.status === 401 || error.status === 403) {
      // this.userService.logout();
      return throwError(error);
    }
    if (error.error?.message || error.error?.code) {
      console.log(error);
      this.snack.open(error.error?.message || error.error?.code, undefined, {
        duration: 3000,
      });
    }
    return throwError(error);
  }

  get(url: string) {
    return this.http.get(url, { withCredentials: true }).pipe(
      timeout(30000),
      catchError((error: any) => {
        return this.errorHandler(error);
      })
    );
  }

  get2(url: string) {
    return this.http
      .get<{ status: string; data: any }>(url, { withCredentials: true })
      .pipe(
        timeout(30000),
        catchError((error: any) => {
          return this.errorHandler(error);
        })
      );
  }

  post(url: string, body: any) {
    return this.http.post(url, body, { withCredentials: true }).pipe(
      timeout(30000),
      catchError((error: any) => {
        return this.errorHandler(error);
      })
    );
  }

  postWithResponse(url: string, body: any) {
    return this.http
      .post<HttpResponse<IResponse>>(url, body, {
        withCredentials: true,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>): any => {
          const newBody = response.body as IResponse;
          console.log(
            'post res',
            response.body,
            response.body instanceof Object
          );
          return response.clone({ body: newBody });
        }),
        timeout(30000),
        catchError((error: any) => {
          return this.errorHandler(error);
        })
      );
  }

  put(url: string, body: any) {
    return this.http.put(url, body, { withCredentials: true }).pipe(
      timeout(30000),
      catchError((error: any) => {
        return this.errorHandler(error);
      })
    );
  }

  delete(url: string) {
    return this.http.delete(url, { withCredentials: true }).pipe(
      timeout(30000),
      catchError((error: any) => {
        return this.errorHandler(error);
      })
    );
  }
  downloadCsv(url: string) {
    return this.http
      .get(url, { withCredentials: true, responseType: 'blob' })
      .pipe(
        timeout(30000),
        catchError((error: any) => {
          return this.errorHandler(error);
        })
      );
  }
}
