import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../services/data.service";
import {FormBuilder} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {IUser} from "../interfaces/jps-interface";

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent {

  nonBoUsers: IUser[] = [];
  constructor(
    private translateService: TranslateService,
    public dataService: DataService,
    private fb: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private _location: Location,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,
  ) {
    this.dataService.getUserProfiles();
    this.dataService.nonBoUsers.subscribe((value: any) => {
      console.log('non bo users from server: ', value);
      if (value) {
        this.nonBoUsers = value;
      }
    });

  }
}
