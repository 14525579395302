import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {DialogButtons, ISeriesData, TopicStatuses, TopicTypes} from "../../../interfaces/jps-interface";
import {TopicDialogComponent} from "../../../topic-dialog/topic-dialog.component";
import {SerieDialogComponent} from "../../serie-dialog/serie-dialog.component";
import {ConfirmDialogComponent} from "../../../ui/confirm-dialog/confirm-dialog.component";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.scss']
})
export class SeriesComponent implements OnInit{
  topic: any;
  topicDocId = '';
  seriesOfTopic: ISeriesData[] = [];
  constructor(
    public dataService: DataService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private _location: Location,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,

  ) {
    // this.activeRoute.params.subscribe((params: any) => {
    //     console.log('params of all bot blocks', params);
    //
    // });
    this.dataService.resetData();
    this.activeRoute.queryParams.subscribe((params: any) => {
      // console.log('query params of all bot blocks', params);
      this.topic = `ID: ${params.topicId}, Name: ${params.topicName}`;
      this.topicDocId = params.topicId;
      this.dataService.getSeriesByTopic(this.topicDocId,);
    });


  }

  ngOnInit() {
    this.dataService.seriesOfTopic.subscribe((seriesValue: any) => {
      console.log('series of topic from server: ', seriesValue);
      if (seriesValue) {
        this.seriesOfTopic = seriesValue;
        this.resetSeriesOrder();
      }
    });
  }
  goBack() {
    this._location.back();
  }

  createNewBlock(series?: ISeriesData) {
    if (!this.topicDocId) {
      this.snackBar.open(this.translateService.instant('Profile ID not found. Please try to refresh page!'))
      return;
    }
    let data: any;
    if (series) {
      data = series;
    } else data = {
      topicId: this.topicDocId,
      order: this.seriesOfTopic.length,
      name: '',
      description: '',
      status: TopicStatuses.ACTIVE,
    };

    const dialogRef = this.dialog.open(SerieDialogComponent, {
      data: data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        console.log('Result is null');
        return;
      }
      console.log('Result is of dialog is: ' + result.event);
      if (result.event === DialogButtons.OK) {
        this.dataService.getSeriesByTopic(this.topicDocId,);

        // reload page
        // this.dataService.getAllTopics(TopicTypes.BOT);
      }
      // this.animal = result;
    });
  }

  openBlockDetail(_id: any) {
    console.log('openBlockDetail', _id);
    this.router.navigate(['quizzes/series/questions'], {
      queryParams: {seriesId: _id, topicId: this.topicDocId},
    }).then();

  }

  deleteBlock(series: any) {
    // delete series
    if (!series) {
      return;
    }

    console.log('series to delete: ', series);
    this.dialog.open(ConfirmDialogComponent, {
      data: { message: 'Confirm_delete_series'},
      disableClose: true,
    }).afterClosed().subscribe((value) => {
      if (value) {
        console.log('delete series: ', value);
        this.dataService.deleteSeriesById(series._id).subscribe({
          next: (value: any) => {
            this.snackBar.open(this.translateService.instant('Delete_data_successfully'));
            this.dataService.getSeriesByTopic(this.topicDocId,);
          },
          error: (e: any) => {
            this.snackBar.open(this.translateService.instant('Delete_data_failed') +  + ' ' + e.error());
          }
        })
      }
    })


  }

  drop($event: CdkDragDrop<ISeriesData>) {
    moveItemInArray(this.seriesOfTopic, $event.previousIndex, $event.currentIndex);
    this.resetSeriesOrder();
  }

  resetSeriesOrder() {
    for (let i = 0; i < this.seriesOfTopic.length; i++) {
      this.seriesOfTopic[i].order = i;
    }
  }
}
