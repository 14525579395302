<!--create reactive form to show user info -->
<!--https://cdn-img.thethao247.vn/resize_696x416/storage/files/camhm/2023/10/12/da-qua-hay-messi-bat-ngo-duoc-doi-thu-cau-xin-mot-dieu-340448.jpg -->
<div style="margin: 24px; ">
  <button mat-raised-button color="primary"
          (click)="goBack()" style="margin-bottom: 20px">{{"Back" | translate}}
  </button>
  <div style="display: flex; margin-bottom: 12px">
    <div class="image-wrapper">
      <img *ngIf="userForm.controls['topicProfile'].value"
        [src]="userForm.controls['topicProfile'].value"
        alt="avatar">
    </div>
    <h1 style=" margin-top: 5px;"> {{'User_detail' | translate }}</h1>
    <h1 style="margin-left: 12px; margin-top: 5px;">- ID: {{userForm.controls['_id'].value}}</h1>

  </div>
  <div style="display: flex; flex-direction: column">
    <form [formGroup]="userForm" class="bot-block-form">
      <!--      show user avatar as circle-->

      <mat-form-field>
        <mat-label>{{'Email' | translate }} ID</mat-label>
        <input matInput formControlName="username">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{'firstName' | translate }}</mat-label>
        <input matInput formControlName="firstName">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'lastName' | translate }}</mat-label>
        <input matInput formControlName="lastName">
      </mat-form-field>

      <a>{{'avatar_guide' | translate}}</a>
      <mat-form-field>
        <mat-label>{{'Avatar_image_link' | translate }}</mat-label>
        <input matInput formControlName="topicProfile">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{'new_password' | translate }}</mat-label>
        <input matInput formControlName="password">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{'department' | translate }}</mat-label>
        <input matInput formControlName="department">
      </mat-form-field>


      <!--    <div style="display: flex">
            <mat-form-field>
              <mat-label>{{'Bot_Profile_name' | translate }}</mat-label>
              <input matInput formControlName="botProfileName">
            </mat-form-field>

            <mat-form-field style="margin-left: 24px">
              <mat-label>{{'Bot_Profile_id' | translate }}</mat-label>
              <input matInput formControlName="botProfileId">
            </mat-form-field>
          </div>

          <div style="display: flex">
            <mat-form-field>
              <mat-label>{{'Quiz_Profile_name' | translate }}</mat-label>
              <input matInput formControlName="quizProfileName">
            </mat-form-field>

            <mat-form-field style="margin-left: 24px">
              <mat-label>{{'Quiz_Profile_id' | translate }}</mat-label>
              <input matInput formControlName="quizProfileId">
            </mat-form-field>
          </div>-->
    </form>

    <!--    button to update profile-->
    <button mat-raised-button color="primary" (click)="updateProfile() "

            style="margin-top: 24px; width: 100px" [disabled]="!userForm.valid">{{'update' | translate }}

    </button>
  </div>
</div>
