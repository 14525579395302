import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogButtons, ISeriesData, ITopicData, TopicStatuses} from "../../interfaces/jps-interface";
import {DataService} from "../../services/data.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-serie-dialog',
  templateUrl: './serie-dialog.component.html',
  styleUrls: ['./serie-dialog.component.scss']
})
export class SerieDialogComponent {

  form: FormGroup;
  editMode = false;
  topicStatues = [
    {value: TopicStatuses.ACTIVE, viewValue: 'Active'},
    {value: TopicStatuses.INACTIVE, viewValue: 'Inactive'},
  ];

  constructor(
    private dataService: DataService,
    public dialogRef: MatDialogRef<SerieDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISeriesData,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {
    if (data._id) {
      this.editMode = true;
    }
    this.form = new FormGroup({
      _id: new FormControl(data._id),
      order: new FormControl(data.order, [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
      ]),
      name: new FormControl(data.name, Validators.required),
      description: new FormControl(data.description),
      status: new FormControl(data.status, Validators.required),
      topicId: new FormControl(data.topicId, Validators.required),
    });

    this.form.controls['status'].disable();
  }

  onNoClick() {
    console.log('onNoClick in create new topic dialog');
    this.dialogRef.close({event: DialogButtons.CANCEL});
  }

  onSubmit() {
    console.log('onSubmit in create new topic dialog', this.form.value);

    // check if order is number
    if (this.form.value.order) {
      if (isNaN(this.form.value.order)) {
        this.snackBar.open(
          this.translateService.instant('order_must_be_number'),);
        return;
      }
    }
    this.spinner.show();
    if (!this.editMode) {
      this.dataService.createSeries(this.form.value).subscribe(
        {
          next: (value: any) => {
            this._onNext(value);

          },
          error: (e) => {
            this._onError(e);
          }
        }
      )
    } else {
      this.dataService.updateSeries(this.form.value).subscribe(
        {
          next: (value: any) => {
            this._onNext(value);
          },
          error: (e) => {
            this._onError(e);
          }
        }
      )
    }

    this.dataService.getSeriesByTopic(this.form.value.topicId,);
  }

  _onNext(value: any) {
    console.log('createTopic: ', value);
    this.spinner.hide();
    this.dialogRef.close({event: DialogButtons.OK});
    this.snackBar.open(
      this.translateService.instant('data_saved_ok'),);
  }

  _onError(e: any) {
    this.spinner.hide();
    this.snackBar.open(
      this.translateService.instant('data_saved_failed'),);
    // this.dialogRef.close({event: 'cancel'});
  }
}
