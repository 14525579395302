import {Component, OnInit} from '@angular/core';
import {DataService} from "../services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {BotBlockComponent} from "../bot-block/bot-block.component";
import {TopicDialogComponent} from "../topic-dialog/topic-dialog.component";
import {DialogButtons, ITopicData, TopicStatuses, TopicTypes} from "../interfaces/jps-interface";
import {Location} from '@angular/common';
import {MatSnackBar} from "@angular/material/snack-bar";
import {InfoDialogComponent} from "../ui/info-dialog/info-dialog.component";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../services/user.service";

@Component({
  selector: 'app-topics-pane',
  templateUrl: './topics-pane.component.html',
  styleUrls: ['./topics-pane.component.scss']
})
export class TopicsPaneComponent implements OnInit {
  botType: any;

  public topics: ITopicData[] = [];

  isSuperAdmin = false;
  constructor(
    private dataService: DataService,
    private router: Router,
    public dialog: MatDialog,
    private _location: Location,
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public userService: UserService,
  ) {
    this.activeRoute.queryParams.subscribe((params: any) => {
      this.botType = params;
      console.log('query params of bot types:', params, this.botType);

    });

    this.isSuperAdmin = this.userService.isSuperAdmin();
  }

  ngOnInit(): void {
    this.dataService.getAllTopics(TopicTypes.BOT);
    this.dataService.topics.subscribe((value: any) => {
      console.log('topics: ', value);
      this.topics = value.data;
    });
  }

  openBlocks(topic: any) {
    if (!topic) {
      console.log('Topic id is empty');
      return;
    }
    // navigate to blocks page
    this.router.navigate(['/blocks'],
      {queryParams: {topicId: topic._id, topicName: topic.name}}).then();
  }

  openAddTopicDialog(topic?: ITopicData) {
    // if (!this.botType) {
    //   console.log('Bot type is empty');
    //   this.snackBar.open('Bot type is empty',);
    //   return;
    // }

    let data: any;
    if (topic) {
      data = topic;
    } else data = {
      // botTypeId: this.botType.type,
      // botTypeName: this.botType.name,
      name: '',
      description: '',
      status: TopicStatuses.ACTIVE,

    };

    data = {
      ...data,
      topicType: TopicTypes.BOT,
    }
    const dialogRef = this.dialog.open(TopicDialogComponent, {
      data: data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        console.log('Result is null');
        return;
      }
      console.log('Result is of dialog is: ' + result.event);
      if (result.event === DialogButtons.OK) {
        // reload page
        this.dataService.getAllTopics(TopicTypes.BOT);
      }
      // this.animal = result;
    });
  }

  goBack() {
    // this._location.back();
  }

  editTopic() {
    console.log('edit topic');
  }

  deleteTopic(topic: any) {
    console.log('topic to delete: ', topic);

    if (!topic) {
      return;
    }


    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        'title': 'Delete_topic',
        'content': 'Confirm_delete_topic',
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      // call delete api
      // if (result.event === DialogButtons.OK) {

      this.spinner.show().then();
      this.dataService.deleteTopic(topic._id).subscribe({
        next: (value: any) => {
          this.snackBar.open(this.translateService.instant('Delete_data_successfully'));
          this.dataService.getAllTopics(TopicTypes.BOT);
          this.spinner.hide().then();
        },
        error: (error: any) => {
          this.snackBar.open(this.translateService.instant('Fail_to_delete_data'));
          this.spinner.hide().then();

        },
      });
    });

  }
}

