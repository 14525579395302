<!--<div class="container">-->
<div
  style="
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    width: 300px;
  "
>
  <img
    src="assets/chatbot.png"
    alt="jps-meta"
    style="width: 100px; height: 100px; margin: auto"
  />
  <span style="height: 50px"></span>
  <span style="margin: auto; font-size: 50px; color: #605dc8">JPS Mase</span>

  <form [formGroup]="loginForm" novalidate style="margin-top: 50px">
    <mat-form-field style="width: 100%; margin: auto">
      <mat-label>{{ "username" | translate }}</mat-label>
      <input matInput formControlName="email" required />
      <mat-error
        *ngIf="loginForm.controls['email'].touched && loginForm.controls['email'].errors?.['required']"
      >
        {{ "username_required" | translate }}
      </mat-error>
    </mat-form-field>

    <!--    create mat-form-field password -->
    <!--    <div style="margin-top: 24px"></div>-->
    <mat-form-field style="width: 100%; margin-top: 24px">
      <mat-label>{{ "password" | translate }}</mat-label>
      <input
        matInput
        formControlName="password"
        required
        [type]="hide ? 'password' : 'text'"
      />
      <mat-icon matSuffix (click)="hide = !hide" color="primary">{{
        hide ? "visibility_off" : "visibility"
      }}</mat-icon>

      <mat-error
        *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].errors?.['required']"
      >
        {{ "password_required" | translate }}
      </mat-error>
    </mat-form-field>

    <div style="display: flex">
      <button
        mat-raised-button
        color="primary"
        (click)="onSubmit()"
        style="margin: 16px auto auto"
        [disabled]="!loginForm.valid"
      >
        {{ "login" | translate }}
      </button>
    </div>

    <!--  <div class="form-group input-block">
      <div>
        <label for="email-address">Username</label>
      </div>
      <div>
        <input id="email-address" type="email" class="form-control" formControlName="email" placeholder="Username">

      </div>
      <small class="form-text text-muted"
             *ngIf="loginForm.controls['email'].touched && loginForm.controls['email'].errors?.['required']">
        Please enter username!
      </small>

      <small class="form-text text-muted"
             *ngIf="loginForm.controls['email'].touched && loginForm.controls['email'].errors?.['email']">
        Email address not well formed!
      </small>

    </div>
    <div class="form-group input-block">

      <div>
        <label for="password">Password</label>
      </div>
      <input id="password" type="password" class="form-control" formControlName="password" placeholder="Password">
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid">Login</button>-->
  </form>
</div>
<!--</div>-->
