<div class="container">
  <header class="header">
    <div class="logo" style="display: flex; margin: 16px 10px 10px 50px;">
      <img src="assets/chatbot.png" alt="jps-meta" style="width: 50px; height: 50px; margin:auto">
      <div style="margin-left: 20px; margin-top: 10px; font-size: 24px; color:#605DC8">JPS Mase</div>
    </div>
    <div class="logout" *ngIf="userService.getCurrentUser()" style="margin-right: 40px">
      <span style="margin-right: 16px">Bonjour {{userService.getCurrentUser()?.firstName}}  {{userService.getCurrentUser()?.lastName}}</span>
      <button class="btn btn-primary" (click)="logout()">Logout</button>
<!--       logout button -->

    </div>
  </header>
  <div class="content-body">
    <nav class="left" *ngIf="userService.getCurrentUser()">
      <app-left></app-left>
    </nav>


    <main class="content">


      <router-outlet></router-outlet>
    </main>
    <!--            <aside class="right">Aside</aside>-->
  </div>
<!--        <footer class="footer">Footer</footer>-->
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#fff"
             type="ball-scale-multiple"
             [fullScreen]="true"><p style="color: white"> Processing ... </p>
</ngx-spinner>
