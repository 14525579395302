import {Component, OnInit} from '@angular/core';
import {DataService} from "../../services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {Location} from "@angular/common";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../services/user.service";
import {DialogButtons, ITopicData, TopicStatuses, TopicTypes} from "../../interfaces/jps-interface";
import {TopicDialogComponent} from "../../topic-dialog/topic-dialog.component";
import {InfoDialogComponent} from "../../ui/info-dialog/info-dialog.component";

@Component({
  selector: 'app-quiz-topic',
  templateUrl: './quiz-topic.component.html',
  styleUrls: ['./quiz-topic.component.scss']
})
export class QuizTopicComponent implements OnInit {

  isSuperAdmin = false;
  public topics: ITopicData[] = [

  ];

  constructor(
    private dataService: DataService,
    private router: Router,
    public dialog: MatDialog,
    private _location: Location,
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public userService: UserService,
  ) {
    this.activeRoute.queryParams.subscribe((params: any) => {
      // this.botType = params;
      // console.log('query params of bot types:', params, this.botType);

    });

    this.isSuperAdmin = this.userService.isSuperAdmin();
  }

  topicType = TopicTypes.QUIZ;
  ngOnInit(): void {
    this.dataService.getAllTopics(this.topicType);
    this.dataService.topics.subscribe((value: any) => {
      console.log('topics: ', value);
      this.topics = value.data;
    });
  }
  openQuizTopic(topic: any) {
    if (!topic) {
      console.log('Topic id is empty');
      return;
    }
    // navigate to blocks page
    this.router.navigate(['/quizzes/series'],
      {queryParams: {topicId: topic._id, topicName: topic.name}}).then();
  }

  openAddTopicDialog(topic?: ITopicData) {
    let data: any;

    if (topic) {
      data = topic;
    } else data = {
      // botTypeId: this.botType.type,
      // botTypeName: this.botType.name,
      name: '',
      description: '',
      status: TopicStatuses.ACTIVE,

    };

    data = {
      ...data,
      topicType: this.topicType,
    }

    const dialogRef = this.dialog.open(TopicDialogComponent, {
      data: data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        console.log('Result is null');
        return;
      }
      console.log('Result is of dialog is: ' + result.event);
      if (result.event === DialogButtons.OK) {
        // reload page
        this.dataService.getAllTopics(TopicTypes.QUIZ);
      }
      // this.animal = result;
    });
  }

  protected deleteTopic(topic: any) {
    console.log('topic to delete: ', topic);

    if (!topic) {
      return;
    }


    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        'title': 'Delete_topic',
        'content': 'Confirm_delete_topic',
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      // call delete api
      // if (result.event === DialogButtons.OK) {

      this.spinner.show().then();
      this.dataService.deleteTopic(topic._id).subscribe({
        next: (value: any) => {
          this.snackBar.open(this.translateService.instant('Delete_data_successfully'));
          this.dataService.getAllTopics(this.topicType);
          this.spinner.hide().then();
        },
        error: (error: any) => {
          this.snackBar.open(this.translateService.instant('Fail_to_delete_data'));
          this.spinner.hide().then();

        },
      });
    });

  }
}
