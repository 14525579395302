import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild} from '@angular/core';
import {
  AbstractControl,
  Form,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {BlockIdValidator} from "../utils/block-id-validator";
import {DataService} from "../services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AppConst, BlockTypes, IReplyData, ReplyTypes} from "../interfaces/jps-interface";
import {Location} from '@angular/common';
import {debounceTime, distinctUntilChanged, first, of, Subject, switchMap} from "rxjs";
import {MatMenuTrigger} from "@angular/material/menu";
import {wait} from "../utils/utils";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";

interface SelectOption {
  id: string;
  viewValue: string;
}

@Component({
  selector: 'app-bot-block',
  templateUrl: './bot-block.component.html',
  styleUrls: ['./bot-block.component.scss']
})
export class BotBlockComponent implements OnInit, AfterViewInit, OnDestroy {
  // searchTerm$ = new Subject<string>();
  // @ViewChild(MatMenuTrigger,) trigger?: MatMenuTrigger;
  @ViewChild('nextBlockNameInput') inputs?: QueryList<any>;
  blockTypes: SelectOption[] = [
    {id: BlockTypes.START, viewValue: 'Start-block'},
    {id: BlockTypes.NORMAL, viewValue: 'Normal-block'},
    {id: BlockTypes.END, viewValue: 'End-block'},
    // {id: BlockTypes.TEXT_ONLY, viewValue: 'Text-only'},

  ];

  replyTypes: SelectOption[] = [
    // {id: ReplyTypes.TEXT, viewValue: 'Reply-Free-Text'},
    {id: ReplyTypes.SINGLE_CHOICE, viewValue: 'Reply-Single-Choice'},
    {id: ReplyTypes.MULTI_CHOICES, viewValue: 'Reply-Multiple-Choices'},
    {id: ReplyTypes.NONE, viewValue: 'Reply-None'},
  ];


  botBlockFormGroup: FormGroup = this.fb.group({
    _id: new FormControl(null),
    topicId: new FormControl(null),
    order: new FormControl(0),
    blockName: new FormControl('',
      {
        validators: [Validators.required],
        // asyncValidators: [BlockIdValidator.uniqueId(this.dataService)],
        // updateOn: 'blur',
      }
    ),
    blockType: new FormControl(BlockTypes.NORMAL, Validators.required),
    replyType: new FormControl('',),
    message: new FormControl('', Validators.required),
    replies: this.fb.array([]), // check: IReplyData[]
  });

  tag = 'BotBlockComponent';
  topicId: string = '';
  blockDocId: string = ''; // this is id generated by MongoDB
  lastSearchSubscription: any;
  currentActiveNextBlockIndex: any;
  lastBlockTapName = '';
  lastBlurIndex = -1;

  constructor(
    private translateService: TranslateService,
    public dataService: DataService,
    private fb: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private _location: Location,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,


  ) {
    // this.botBlockFormGroup = this.fb.group({
    //   _id: new FormControl(null),
    //   topicId: new FormControl(null),
    //   blockName: new FormControl('',
    //     {
    //       validators: [Validators.required],
    //       asyncValidators: [BlockIdValidator.uniqueId(this.dataService)],
    //       // updateOn: 'blur',
    //     }
    //   ),
    //   blockType: new FormControl(BlockTypes.NORMAL, Validators.required),
    //   replyType: new FormControl('', Validators.required),
    //   message: new FormControl('', Validators.required),
    //   replies: this.fb.array([]), // check: IReplyData[]
    // });

    this.dataService.resetData();

    // this.replies.valueChanges.subscribe((value: any) => {
    //     console.log('replies value changes', value, this.inputs);
    //     // this.inputs?.changes.subscribe((value: any) => {
    //     //     console.log('inputs changes', value);
    //     // });
    // });
    // this.botBlockFormGroup.controls['blockName'].valueChanges
    //     .pipe(debounceTime(5000))
    //     .subscribe((value: any) => {
    //             console.log('blockName value changes', value);
    //         }
    //     );

    /**
     * Due to asyncValidators, we need to markAsPristine() when replyType changes
     * because when replyType changes, blockName will be in 'pending' state
     * for more info:
     * https://angular.io/errors/NG0100
     * https://indepth.dev/posts/1001/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error
     */
    this.botBlockFormGroup.controls['replyType'].valueChanges.subscribe((value: any) => {
      this.botBlockFormGroup.controls['blockName'].markAsPristine();
      this.botBlockFormGroup.controls['replies'].markAsPristine();
      console.log('replyType value changes', value);
      this.removeAllReplies();
      if (value === ReplyTypes.NONE) {
        // this.botBlockFormGroup.controls['message'].disable();
        this.addReply();
        // set content to 'abc'
        this.replies.at(0).get('content')?.setValue('none-reply');
      }

    });
    this.botBlockFormGroup.valueChanges.subscribe(
      (data: any) => {
        console.log('form value changes xxx', this.botBlockFormGroup);

        // wait(1000).then(() => {
        //     console.log('new input may come', this.inputs);
        //
        // });
      }
    );

    if (location.href.includes('/new?topicId=')) {
      this.activeRoute.queryParams.subscribe((params: any) => {
        this.topicId = params.topicId;
        // console.log('query params of bot block', params, this.topicId);
        this.botBlockFormGroup.patchValue({
          topicId: this.topicId,
          order: params.order,
        });

        // this.botBlockFormGroup.controls['blockName'].addAsyncValidators(
        //   [BlockIdValidator.uniqueId(this.dataService, '')],
        // );


      });
    } else {
      this.activeRoute.queryParams.subscribe((params: any) => {
        this.topicId = params.topicId;
        this.blockDocId = params.blockId;
        // console.log('query params of bot block', params, this.topicId);
        this.dataService.getBlockById(this.blockDocId);
        // this.dataService.getBlocksByTopic(this.topicId, 'queryParams botblockcompo');
        this.initFormWithCurrentValue();


      });
    }

    this.dataService.getBlocksByTopic(this.topicId, 'botblockcompo');


  }

  ngOnInit() {
/*      this.botBlockFormGroup.controls['blockType'].valueChanges.subscribe((value: any) => {
        if (value === BlockTypes.END) {
          this.botBlockFormGroup.controls['replyType'].setValue(ReplyTypes.NONE);
          this.botBlockFormGroup.controls['replyType'].disable();
          this.botBlockFormGroup.controls['replies'] = this.fb.array([]);
          this.botBlockFormGroup.controls['replies'].disable();

        } else {
          this.botBlockFormGroup.controls['replyType'].enable();
        }
      });*/
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit()');

  }

  initFormWithCurrentValue() {
    this.dataService.blockDetailSubject
      .subscribe((block: any) => {
        if (!block) {
          return;
        }
        // console.log('block detail', block);
        // const replies = block.replies;
        // delete block.replies;
        this.botBlockFormGroup.patchValue(block);
        this.replies.clear();
        block.replies.forEach((reply: any) => {
          this.addNewReplyControl(reply);
        });

        // this.botBlockFormGroup.controls['blockName'].addAsyncValidators(
        //   [BlockIdValidator.uniqueId(this.dataService, block.blockName)],
        // )
      });
  }

  addNewReplyControl(data?: IReplyData) {

    this.replies.push(this.fb.group({
      content: [data ? data.content : '', Validators.required],
      nextBlockName: [data ? data.nextBlockName : '',

        {
          // validators: [Validators.required],

          asyncValidators: [BlockIdValidator.blockExist(this.dataService)],
        }
      ],
      //
      nextBlockId: [data ? data.nextBlockId : '',
        {
          // validators: [BlockIdValidator.blockIdExist(this.dataService)],
          // asyncValidators: [BlockIdValidator.blockExist(this.dataService)],
          // updateOn: 'blur',
        }
      ],

    }));

    // if (this.botBlockFormGroup.controls['replyType'].value === ReplyTypes.MULTI_CHOICES) {
    //    this.replies.disable();
    // }

    // this.replies.at(0).get('nextBlockName')?.valueChanges.subscribe((value: any) => {
    //   console.log('nextBlockName value changes xxx', value);
    // });
    //
    // this.replies.at(0).get('nextBlockId')?.valueChanges.subscribe((value: any) => {
    //   console.log('nextBlockId value changes xxx', value);
    // });
  }

  getBlockNameErrorMessage() {
    if (this.botBlockFormGroup.controls['blockName'].hasError('required')) {
      return 'Block_name_required';
    }

    if (this.botBlockFormGroup.controls['blockName'].hasError('exist')) {
      console.log('Block_name_exist');
      return 'Block_name_exist';
    }

    return 'unknown_input_error';

    // return this.botBlockFormGroup.controls['blockName'].hasError('email') ? 'Not a valid email' : '';
  }

  removeReply(i: number) {
    this.replies.removeAt(i);

  }

  removeAllReplies() {
    this.replies.clear();
  }

  addReply() {
    this.addNewReplyControl();
  }

  get replies() {
    return this.botBlockFormGroup.get('replies') as FormArray;
  }

  protected readonly ReplyTypes = ReplyTypes;

  goBack() {
    this._location.back();
    // reload page
    this.dataService.getBlocksByTopic(this.topicId, 'goBack botblockcompo');
  }

  onNoClick() {
    this.goBack();
  }

  onSubmit(close: boolean = false) {
    if (!this.botBlockFormGroup.valid) {
      console.log('form is not valid');
      return;
    }


    this.replies.controls.forEach((control: any) => {

      let block = this.dataService.findBlock(control.get('nextBlockName').value);
      console.log('block return', block);
      if (!block) {
        // show error
        control.get('nextBlockName').setErrors({exist_id: true});
        return;
      }

      control.get('nextBlockId').setValue(block._id);
    });
    this.spinner.show();
    console.log('form data to submitted', this.botBlockFormGroup.value);
    this.dataService.createBlock(this.botBlockFormGroup.value).subscribe({
      next: (value: any) => {
        console.log('createBlock: ', value);
        this.dataService.getBlocksByTopic(this.botBlockFormGroup.value.topicId, 'createBlock()');
        this.spinner.hide();
        this.snackBar.open(
          this.translateService.instant('data_saved_ok'),
          undefined, {duration: 3000});

        if (close) {
          setTimeout(() => {
            this.goBack();
          }, 300);
        }

      },
      error: (e) => {
        this.spinner.hide();
        console.log('create block error', e);
        this.snackBar.open(
          this.translateService.instant('data_saved_failed') + ': ' + e.error.message,
          undefined, {duration: 3000});
      }
    });

  }


  onNextBlockNameFocused(i: number) {
    if (this.currentActiveNextBlockIndex === i) {
      console.log('focus unchanged');
      return;
    }

    console.log('focus on index ', i);
    this.currentActiveNextBlockIndex = i;
    this.lastSearchSubscription?.unsubscribe();

    this.dataService.findBlocksByName(this.replies?.at(i)?.get('nextBlockName')?.value).subscribe(
      (value: any) => {
        this.dataService.foundBlocks = value.data;
        this.lastSearchSubscription = this.replies?.at(i)
          ?.get('nextBlockName')
          ?.valueChanges
          .pipe(
            debounceTime(AppConst.DEBOUNCE_TIME),
            distinctUntilChanged(),
            switchMap((value) => {
                // if (value === this.lastBlockTapName) {
                //     return this.dataService.findBlocksByName('');
                // }
                return this.dataService.findBlocksByName(value);
              }
            ),
            // first()
          )
          .subscribe({
            next: (value: any) => {
              this.dataService.foundBlocks = value.data;
              console.log('found blocks', this.dataService.foundBlocks);
              // this.trigger?.openMenu();

            }, error: () => {
              this.dataService.foundBlocks = [];
            },
          });
      },
    );

  }

  // protected readonly FormControl = FormControl;
  // need for dynamic adds of elements to re
  //focus may not be needed by others
  trackByFn(index: any, item: any) {
    console.log('trackByFn', index, item);
    return index;
  }

  getNextBlockNameErrorMessage(control: AbstractControl) {
    const nextBlockName = control.get('nextBlockName');
    if (nextBlockName?.hasError('required')) {
      return 'Next_block_name_required';
    }

    if (nextBlockName?.hasError('exist')) {
      // console.log('Next_block_name_exist');
      return 'Next_block_name_not_exist';
    }


    if (nextBlockName?.hasError('exist_id')) {
      // console.log('Next_block_name_exist');
      return 'Next_block_name_not_exist_id';
    }

    return 'unknown_input_error';

  }

  getNextBlockIdErrorMessage1(control: AbstractControl) {
    const nextBlockId = control.get('nextBlockId');
    if (nextBlockId?.hasError('required')) {
      return 'Next_block_id_required';
    }

    if (nextBlockId?.hasError('not-exist')) {
      // console.log('Next_block_name_exist');
      return 'Next_block_id_not_exist';
    }

    return 'unknown_input_error';

  }

  onBlockSelected(block: any) {
    console.log('debug - block selected', block);

    this.lastBlockTapName = block.blockName;
    this.replies?.at(this.currentActiveNextBlockIndex)?.get('nextBlockName')?.setValue(block.blockName);
    // this.replies?.at(this.currentActiveNextBlockIndex)?.get('nextBlockId')?.setValue(block._id);
  }

  onBlur(i: number) {
    console.log('debug - onBlur', i);
    this.lastBlurIndex = i;
    this.dataService.findBlocksByName('');
    // wait(500).then(() => {
    //     this.dataService.findBlocksByName('').subscribe(
    //         (value: any) => {
    //             this.dataService.foundBlocks = value.data;
    //         }
    //     );
    // });
  }

  ngOnDestroy() {
    console.log(`${this.tag} ngOnDestroy`);
    this.lastSearchSubscription?.unsubscribe();
  }

  protected readonly BlockTypes = BlockTypes;
}
