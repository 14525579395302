import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../../../../services/data.service";
import {AbstractControl, FormArray, FormBuilder, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {IQuestionData} from "../../../../interfaces/jps-interface";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../../../ui/confirm-dialog/confirm-dialog.component";
import {InfoDialogComponent} from "../../../../ui/info-dialog/info-dialog.component";

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent {

  panelOpenStates: boolean[] = [];
  // allThings = [
  //   'What is the capital of France?',
  //   'What is the capital of Vietnam?',
  // ]

  // question: IQuestionData | undefined;
  formGroup = this.fb.group({
    seriesId: ['', Validators.required],
    questions: this.fb.array([]),
  });

  // questionsForm: FormArray = this.fb.array([]);
  seriesId: any;

  constructor(
    private translateService: TranslateService,
    public dataService: DataService,
    private fb: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private _location: Location,
    private dialog: MatDialog,

  ) {
    // this.panelOpenStates = this.questionsForm.controls.map(() => false);
    // this.panelOpenStates = this.allThings.map(() => true);
    this.formGroup.valueChanges.subscribe((value: any) => {
      console.log('formGroup value changes: ', value);
    });

    this.activeRoute.queryParams.subscribe((params: any) => {
      console.log('query params of questions page', params);
      this.seriesId = params.seriesId;
      this.formGroup.get('seriesId')?.setValue(this.seriesId);
      this.dataService.getQuestionsOfSeries(this.seriesId);
    });

    this.dataService.questionsOfSeries$.subscribe((questionsValue: any) => {
      console.log('questions of series from server: ', questionsValue);
      if (questionsValue) {
        // patch value to questions form
        this.questions.clear();
        questionsValue.forEach((question: IQuestionData) => {
          this.addNewQuestionControl(question);
          // add choices
          console.log('question xxx: ', question);

          for (let i = 0; i < question.choices.length; i++) {
            this.addAnswer(question.order, question.choices[i]);
          }
        });
      }
    });
  }

  addQuestion() {
    // this.panelOpenStates.push(true);

    // this.allThings.push('New question');

    this.addNewQuestionControl();
    this.questions.controls[this.questions.controls.length - 1]
      .get('order')
      ?.setValue(this.questions.controls.length - 1);
    // wait for form to be rendered
    setTimeout(() => {
      window.scrollTo(0,document.body.scrollHeight);
    }, 300);
  }


  addNewQuestionControl(data?: IQuestionData) {
    // this.questions.push(this.fb.group({
    //   order: [data?.order, Validators.required],
    //   theme: [data?.theme, Validators.required],
    //   question: [data?.question, Validators.required],
    //   choices: this.fb.array([]),
    // }));

    this.questions.push(this.fb.group({
      order: [data?.order, Validators.required],
      theme: [data?.theme, Validators.required],
      question: [data?.question, Validators.required],
      choices: this.fb.array([]),
    }));
  }

  get questions() {
    return this.formGroup.get('questions') as FormArray;
  }

  getQuestionChoices(i: number) {
    return this.questions.controls[i].get('choices') as FormArray;
  }

  addAnswer(questionIndex: number, data?: any) {
    console.log('add answer for question: ', questionIndex);
    this.getQuestionChoices(questionIndex).push(this.fb.group({
      choice: [data?.choice, Validators.required],
      isCorrect: [data?.isCorrect ?? false, Validators.required],
    }));
    // control.push(this.fb.group({
    //   choice: ['', Validators.required],
    //   isCorrect: [false, Validators.required],
    // }));
  }

  drop(event: CdkDragDrop<AbstractControl[]>) {
    moveItemInArray(this.questions.controls, event.previousIndex, event.currentIndex);
    this.resetOrder();
  }

  resetOrder() {
    for (let [index, control] of this.questions.controls.entries()) {
      control.get('order')?.setValue(index);
    }
  }
  submit() {
    if (this.formGroup.invalid || !this.seriesId || this.questions.controls.length == 0) {
      console.log('form invalid');
      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          'title': 'Data_invalid',
          'content': 'Data_invalid_message',
        },
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(result => {});
      // show info dialog
      return;
    }

    this.dataService.updateQuestionsOfSeries(this.formGroup.value);
  }

  isFormValid() {
    return !(this.formGroup.invalid || !this.seriesId || this.questions.controls.length == 0);

  }

  goBack() {
    this._location.back();

  }

  deleteQuestion(questionIndex: number) {
    this.dialog.open(ConfirmDialogComponent, {
      data: { message: 'Do you want to delete question?'},
      disableClose: true,
    }).afterClosed().subscribe((value) => {
      if (value) {
        console.log('delete question: ', value);
        this.questions.removeAt(questionIndex);
        this.resetOrder();
      }
    })
  }

  deleteAnswer(questionIndex: number, answerIndex: number) {
      // delete answer
      this.getQuestionChoices(questionIndex).removeAt(answerIndex);
  }
}
