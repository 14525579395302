<!--create back button-->
<button mat-raised-button color="primary"
        (click)="goBack()" style="margin: 20px">{{"Back" | translate}}
</button>
<!--create button to add block-->
<button mat-raised-button color="primary"
        (click)="createNewBlock()">{{"Add_block" | translate}}
</button>
<h3 style="margin-left: 24px;">{{"Topic" | translate}} {{topic}} >> Blocks</h3>
<span style="margin-left: 24px;">
  <mat-icon [ngStyle]="{'color': 'red'}">
    account_tree
  </mat-icon>

</span>
<span style="margin: 10px">
     'red' -> Bloc de conversation peut être impcomplet.
</span>

<div style="justify-content: center; display: flex; max-width: 1400px; height: auto; margin-bottom: 50px">
  <mat-grid-list cols="2" rowHeight="150px" gutterSize="10px" cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-grid-tile *ngFor="let block of this.blocksOfTopic" cdkDrag [ngStyle]="{'background-color': getBlockColor(block._id)}">
      <div style="display: flex; flex-direction: column;  width: 100%; margin-top: 30px ">
        <div style="display: flex; width: 100%; ">
          <div style="color: #ffffff; font-size: x-large; width: 100%; margin-left: 24px">
            Block {{block.blockName}}
          </div>

          <div style="display: flex; width: 100px;">
            <mat-icon fontIcon="account_tree" (click)="openBlockDetail(block._id)" style="margin-right: 16px"
                      [ngStyle]="{'color': isBlockValid(block) ? 'green' : 'red'}"></mat-icon>
            <mat-icon fontIcon="delete" [ngStyle]="{'color': (block.prevBlocks?.length ?? 0 ) > 0 ? 'gray' : 'red'}"
                      (click)="deleteBlock(block)"
                      style="margin-right: 16px"></mat-icon>
          </div>
        </div>
        <div style="width: 100%; height: 130px; background-color: #787878; margin-top: 5px;">
         <div style="margin-left: 10px">
           <div style="height: 40px">
             {{block.message.substring(0, 140)}}
             ...
           </div>
           <div style="display: flex" >
             <div *ngFor="let reply of block.replies" [ngStyle]="{'background-color': getBlockColor(reply.nextBlockId, block)} ">
               [{{block.replyType === ReplyTypes.NONE ? 'NONE' : reply.content}} >> {{reply.nextBlockName}}] &nbsp;
             </div>
           </div>
         </div>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>


</div>


