<!--create back button-->
<button mat-raised-button color="primary"
        (click)="goBack()" style="margin: 20px">{{"Back" | translate}}
</button>
<div style="display: flex; margin-bottom: 50px; margin-left: 50px; overflow-y: hidden">
  <div style="justify-content: start; display: flex; margin-top: 10px; ">

    <form [formGroup]="botBlockFormGroup" class="bot-block-form">
      <span>TopicID: {{topicId}}, </span>
      <br>
      <span>BlockID: {{blockDocId}}, </span>
      <span># {{botBlockFormGroup.controls['order'].value + 1}} </span>
      <p></p>

      <div style="display: flex">
        <mat-form-field class="bot-block-field" style="margin-right: 24px">
          <mat-label>{{'ST_Block_Unique_Name' | translate}}</mat-label>
          <input matInput formControlName="blockName" autocomplete="off">
          <mat-error>{{getBlockNameErrorMessage() | translate}}
          </mat-error>
        </mat-form-field>


        <mat-form-field style="width: 250px">
          <mat-label>{{"ST_Block_type" | translate}}</mat-label>
          <mat-select formControlName="blockType">
            <mat-option *ngFor="let type of blockTypes" [value]="type.id">
              {{type.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <mat-form-field class="bot-block-field">
        <mat-label>{{"ST_Message" | translate}}</mat-label>
        <textarea matInput formControlName="message" autocomplete='disabled'
                  cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3">
            </textarea>
      </mat-form-field>

      <mat-form-field class="bot-block-field" >
        <mat-label>{{"ST_Reply_type" | translate}}</mat-label>
        <mat-select formControlName="replyType">
          <mat-option *ngFor="let type of replyTypes" [value]="type.id">
            {{type.viewValue | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

<!--       *ngIf="BlockTypes.END !== botBlockFormGroup.controls['blockType'].value"-->
      <ng-container [ngSwitch]="botBlockFormGroup.controls['replyType'].value"
                   >
        <ng-container *ngSwitchCase="ReplyTypes.NONE" [ngTemplateOutlet]="answerNone"></ng-container>
        <ng-container *ngSwitchCase="ReplyTypes.MULTI_CHOICES"
                      [ngTemplateOutlet]="answerMultipleChoices"></ng-container>
        <ng-container *ngSwitchCase="ReplyTypes.SINGLE_CHOICE"
                      [ngTemplateOutlet]="answerSingleChoices"></ng-container>
        <ng-container *ngSwitchDefault></ng-container>
      </ng-container>

      <div style="display: flex; justify-content: center; margin-top: 24px;">

        <!-- cancel button-->
        <button mat-raised-button color="warn" (click)="onNoClick()">{{'Cancel' | translate}}</button>
        <span style="width: 40px"></span>
        <!-- submit button-->
        <button mat-raised-button color="primary" (click)="onSubmit()"
                [disabled]="!botBlockFormGroup.valid">{{'Save' | translate}}</button>
        <span style="width: 40px"></span>
        <button mat-raised-button color="primary" (click)="onSubmit(true)"
                [disabled]="!botBlockFormGroup.valid">{{'Save_and_close' | translate}}</button>
      </div>

    </form>

  </div>

  <div style=" margin-top: 65px; margin-left: 40px; background-color: #808080;
          width: 300px; height: 700px; color: black; overflow:auto;">
    <p
      style="margin: 8px; color:#ffffff; ">{{'Next_blocks_search_result' | translate}} {{'Tap_to_select' | translate}}</p>
    <mat-card (click)="onBlockSelected(block)" *ngFor="let block of dataService.foundBlocks"
              style="margin: 8px; height: 70px;">
      <mat-card-content>{{block.blockName}}</mat-card-content>
    </mat-card>
  </div>
</div>
<!--<mat-menu #menu> {{"Available Blocks" | translate}}
    <button mat-menu-item *ngFor="let block of dataService.foundBlocks">x</button>
</mat-menu>-->

<ng-template #answerSingleChoices>
  <div [formGroup]="botBlockFormGroup" class="form-with-buttons">
    <ng-container formArrayName="replies">
      <ng-container *ngFor="let control of replies.controls;
                          let i = index;">
        <!-- trackBy: trackByFn -->
        <div [formGroupName]="i" style="display: flex">
          <mat-form-field class="bot-block-field" style="margin-right: 24px">
            <mat-label>{{"ST_Quick_Reply" | translate}}</mat-label>
            <input matInput formControlName="content">
          </mat-form-field>


          <mat-form-field  *ngIf="BlockTypes.END !== botBlockFormGroup.controls['blockType'].value" class="bot-block-field" style="width: 350px">
            <mat-label>{{"ST_Next_Block_Name" | translate}}</mat-label>
            <input #nextBlockNameInput matInput formControlName="nextBlockName"
                   (focus)="onNextBlockNameFocused(i)"
                   (blur)="onBlur(i)"
                   placeholder="{{'type_to_search' | translate }}"
            >
            <mat-error>
              {{ getNextBlockNameErrorMessage(control) | translate}}
            </mat-error>
          </mat-form-field>

          <button mat-icon-button color="warn" (click)="removeReply(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <button mat-raised-button color="primary" (click)="addReply()">{{"ST_Add_choice" | translate}}</button>
</ng-template>

<ng-template #answerMultipleChoices>
  <div [formGroup]="botBlockFormGroup" class="form-with-buttons">
    <ng-container formArrayName="replies">
      <ng-container *ngFor="let control of replies.controls;
                          let i = index;">
        <!-- trackBy: trackByFn -->


        <div [formGroupName]="i" *ngIf="i===0">
          <mat-form-field  *ngIf="BlockTypes.END !== botBlockFormGroup.controls['blockType'].value" class="bot-block-field" style="width: 200px">
            <mat-label>{{"ST_Next_Block_Name" | translate}}</mat-label>
            <input matInput formControlName="nextBlockName"
                   (focus)="onNextBlockNameFocused(i)"
                   (blur)="onBlur(i)"
            >
            <mat-error>
              {{ getNextBlockNameErrorMessage(control) | translate}}
            </mat-error>
          </mat-form-field>
          <div style="display: flex">
            <mat-form-field class="bot-block-field" style="margin-right: 0">
              <mat-label>{{"ST_Quick_Reply" | translate}}</mat-label>
              <input matInput formControlName="content">
            </mat-form-field>

            <button mat-icon-button color="warn" (click)="removeReply(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>

        <div [formGroupName]="i" style="display: flex" *ngIf="i>0">
          <mat-form-field class="bot-block-field" style="margin-right: 0">
            <mat-label>{{"ST_Quick_Reply" | translate}}</mat-label>
            <input matInput formControlName="content">
          </mat-form-field>

          <button mat-icon-button color="warn" (click)="removeReply(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>

      </ng-container>
    </ng-container>
  </div>
  <button mat-raised-button color="primary" (click)="addReply()">{{"ST_Add_choice" | translate}}</button>
</ng-template>

<ng-template #answerNone>
  <div [formGroup]="botBlockFormGroup" class="form-with-buttons">
    <ng-container formArrayName="replies">
      <ng-container *ngFor="let control of replies.controls;
                          let i = index;">
        <!-- trackBy: trackByFn -->
        <div [formGroupName]="i" style="display: flex">
          <!--    <mat-form-field class="bot-block-field" style="margin-right: 24px" >
                <mat-label>{{"ST_Quick_Reply" | translate}}</mat-label>
                <input matInput formControlName="content" readonly>
              </mat-form-field>-->


          <mat-form-field class="bot-block-field" style="width: 200px">
            <mat-label>{{"ST_Next_Block_Name" | translate}}</mat-label>
            <input #nextBlockNameInput matInput formControlName="nextBlockName"
                   (focus)="onNextBlockNameFocused(i)"
                   (blur)="onBlur(i)"
            >
            <mat-error>
              {{ getNextBlockNameErrorMessage(control) | translate}}
            </mat-error>
          </mat-form-field>

          <button mat-icon-button color="warn">
            <mat-icon></mat-icon>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
