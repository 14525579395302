import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {mergeMap, Observable, skipWhile, take, timeout} from 'rxjs';
import {UserService} from "../services/user.service";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  private passWithoutTokenRegex = /\/refresh/;

  constructor(
    private userService: UserService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.userService.getCurrentUser();
    console.log('currentUser', currentUser);
    if (currentUser === null) {
      this.userService.removeCurrentUser();
      return next.handle(req);
    }

    const authData = {
      username: currentUser.username,
      password: currentUser.password,
    }

    const authDataString = currentUser.username + ':' + currentUser.password;
    // console.log('authDataString', authDataString);
    // encodebase 64 username:password
    const encodedAuthData = btoa(authDataString);
    req = req.clone({
      // setHeaders: {Authorization: `username ${currentUser.username} password ${currentUser.password}`},
      setHeaders: {Authorization: `Basic ${encodedAuthData}`},
      withCredentials: true
    })
    console.log('request is', req);
    return next.handle(req);


  }

}
