import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DataService} from "../services/data.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogButtons, ITopicData, TopicStatuses, TopicTypes} from "../interfaces/jps-interface";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../services/user.service";

@Component({
  selector: 'app-topic-dialog',
  templateUrl: './topic-dialog.component.html',
  styleUrls: ['./topic-dialog.component.scss']
})
export class TopicDialogComponent {
  form: FormGroup;
  editMode = false;
  topicStatues = [
    {value: TopicStatuses.ACTIVE, viewValue: 'Active'},
    {value: TopicStatuses.INACTIVE, viewValue: 'Inactive'},
  ];

  constructor(
    private dataService: DataService,
    public dialogRef: MatDialogRef<TopicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITopicData,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private userService: UserService,
  ) {
    if (data._id) {
      this.editMode = true;
    }
    this.form = new FormGroup({
      // botTypeId: new FormControl(data.botTypeId, Validators.required),
      // botTypeName: new FormControl(data.botTypeName, Validators.required),
      _id: new FormControl(data._id),
      name: new FormControl(data.name, Validators.required),
      description: new FormControl(data.description,),
      status: new FormControl(data.status, Validators.required),
      topicType: new FormControl(data.topicType, Validators.required),
    });
    if (!this.userService.isSuperAdmin()) {
      this.form.controls['description'].disable();
    }
    this.form.controls['status'].disable();
  }

  onNoClick() {
    console.log('onNoClick in create new topic dialog');
    this.dialogRef.close({event: DialogButtons.CANCEL});
  }

  onSubmit() {
    console.log('onSubmit in create new topic dialog', this.form.value);
    this.spinner.show();
    if (!this.editMode) {
      this.dataService.createTopic(this.form.value).subscribe(
        {
          next: (value: any) => {
            this._onNext(value);

          },
          error: (e) => {
            this._onError(e);
          }
        }
      )
    } else {
      this.dataService.updateTopic(this.form.value).subscribe(
        {
          next: (value: any) => {
            this._onNext(value);
          },
          error: (e) => {
            this._onError(e);
          }
        }
      )
    }
  }

  _onNext(value: any) {
    console.log('createTopic: ', value);
    this.spinner.hide();
    this.dialogRef.close({event: DialogButtons.OK});
    this.snackBar.open(
      this.translateService.instant('data_saved_ok'));
  }

  _onError(e: any) {
    this.spinner.hide();
    this.snackBar.open(
      this.translateService.instant('data_saved_failed'));
    // this.dialogRef.close({event: 'cancel'});
  }
}
