import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BotBlockComponent } from './bot-block/bot-block.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HomeComponent } from './home/home.component';
import { AllBotBlocksComponent } from './all-bot-blocks/all-bot-blocks.component';
import { MatListModule } from '@angular/material/list';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { TopicsPaneComponent } from './topics-pane/topics-pane.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { TopicDialogComponent } from './topic-dialog/topic-dialog.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { LoginComponent } from './login/login.component';
import { RequestInterceptor } from './helpers/request.interceptor';
import { ApiService } from './services/api.service';
import { AuthenticationService } from './services/authentication.service';
import { DataService } from './services/data.service';
import { UserService } from './services/user.service';
import { LeftComponent } from './left/left.component';
import { QuizzesComponent } from './quizzes/quizzes.component';
import { BotTypesComponent } from './bot-types/bot-types.component';
import { InfoDialogComponent } from './ui/info-dialog/info-dialog.component';
import { AddUsersComponent } from './add-users/add-users.component';
import { QuizTopicComponent } from './quiz-module/quiz-topic/quiz-topic.component';
import { SeriesComponent } from './quiz-module/quiz-topic/series/series.component';
import { SerieDialogComponent } from './quiz-module/serie-dialog/serie-dialog.component';
import { QuestionsComponent } from './quiz-module/quiz-topic/series/questions/questions.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConfirmDialogComponent } from './ui/confirm-dialog/confirm-dialog.component';
import { UserManagementComponent } from './user-managment/user-management.component';
import { UserDetailComponent } from './user-managment/user-detail/user-detail.component';
import { UserQuizComponent } from './user-quiz/user-quiz.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { QuizResultComponent } from './user-quiz/quiz-result/quiz-result.component';
@NgModule({
  declarations: [
    AppComponent,
    BotBlockComponent,
    HomeComponent,
    AllBotBlocksComponent,
    TopicsPaneComponent,
    TopicDialogComponent,
    LoginComponent,
    LeftComponent,
    QuizzesComponent,
    BotTypesComponent,
    InfoDialogComponent,
    AddUsersComponent,
    QuizTopicComponent,
    SeriesComponent,
    SerieDialogComponent,
    QuestionsComponent,
    ConfirmDialogComponent,
    UserManagementComponent,
    UserDetailComponent,
    UserQuizComponent,
    QuizResultComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSnackBarModule,
    MatGridListModule,
    MatDialogModule,
    MatMenuModule,
    MatCardModule,
    MatExpansionModule,
    CdkDropList,
    CdkDrag,
    MatCheckboxModule,
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    UserService,
    ApiService,
    AuthenticationService,
    DataService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2000 } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
