import {inject, Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn} from '@angular/router';
import {AuthenticationService} from "../services/authentication.service";
import {UserService} from "../services/user.service";


/*@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.userService.getCurrentUser();
    console.log('currentUser: ', currentUser);
    if (currentUser) {
      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}}).then(r => console.log('r: ', r));
    return false;
  }
}*/

export const authGuardFn: CanActivateFn = (route, state) => {
  const router = inject(Router);

  const userService = inject(UserService);
  console.log('srRolesGuard route info: ', route);
  const currentUser = userService.getCurrentUser();
  console.log('currentUser: ', currentUser);
  if (currentUser) {
    // authorised so return true
    return true;
  }

  // not logged in so redirect to login page with the return url
  router.navigate(['/login'], {queryParams: {returnUrl: state.url}}).then(r => console.log('r: ', r));
  return false;
 /* let canActivate = userService.user && route.data['allowedRoles'].includes(userService.user?.role);

  if (!canActivate) {
    if (userService.user?.role === SrRoles.ROLE_REC) {
      canActivate = route.data['allowedRoles'].includes(SrRoles.REC_AGENCY_ID);
    } else if (userService.user?.role === SrRoles.ROLE_OWNER) {
      canActivate = route.data['allowedRoles'].includes(SrRoles.OWNER_ID);
    }
  }
  if (!canActivate) {
    console.log('srRolesGuard can NOT activate');
    router.navigate(['management/config']).then();
    return false;
  } else {
    return true;
  }*/

};
