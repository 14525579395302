import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllBotBlocksComponent } from './all-bot-blocks/all-bot-blocks.component';
import { BotBlockComponent } from './bot-block/bot-block.component';
import { TopicsPaneComponent } from './topics-pane/topics-pane.component';
import { HomeComponent } from './home/home.component';
import { authGuardFn } from './helpers/auth.guard';
import { LoginComponent } from './login/login.component';
import { QuizzesComponent } from './quizzes/quizzes.component';
import { BotTypesComponent } from './bot-types/bot-types.component';
import { AddUsersComponent } from './add-users/add-users.component';
import { SeriesComponent } from './quiz-module/quiz-topic/series/series.component';
import { QuestionsComponent } from './quiz-module/quiz-topic/series/questions/questions.component';
import { UserManagementComponent } from './user-managment/user-management.component';
import { UserDetailComponent } from './user-managment/user-detail/user-detail.component';
import { UserQuizComponent } from './user-quiz/user-quiz.component';
// https://stackoverflow.com/questions/44864303/send-data-through-routing-paths-in-angular
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    // children: [
    //   {path: 'block/new', component: BotBlockComponent},
    //   {path: 'block/detail', component: BotBlockComponent},
    //   {path: 'blocks', component: AllBotBlocksComponent},
    // ],
    canActivate: [authGuardFn],
    // canActivateChild: [authGuardFn],
  },
  { path: 'login', component: LoginComponent },
  // {path:'all-bot-blocks', component: AllBotBlocksComponent},
  {
    path: 'block/new',
    component: BotBlockComponent,
    canActivate: [authGuardFn],
  },
  {
    path: 'block/detail',
    component: BotBlockComponent,
    canActivate: [authGuardFn],
  },
  {
    path: 'blocks',
    component: AllBotBlocksComponent,
    canActivate: [authGuardFn],
  },
  { path: 'bots', component: TopicsPaneComponent, canActivate: [authGuardFn] },
  // {path: 'bot-types', component: BotTypesComponent,  canActivate: [authGuardFn],},
  { path: 'quizzes', component: QuizzesComponent, canActivate: [authGuardFn] },
  {
    path: 'quizzes/series',
    component: SeriesComponent,
    canActivate: [authGuardFn],
  },
  {
    path: 'quizzes/series/questions',
    component: QuestionsComponent,
    canActivate: [authGuardFn],
  },
  {
    path: 'users-management',
    component: UserManagementComponent,
    canActivate: [authGuardFn],
  },
  { path: 'user', component: UserDetailComponent, canActivate: [authGuardFn] },
  // {path: 'users', component: AddUsersComponent,  canActivate: [authGuardFn],},
  {
    path: 'users-quiz',
    component: UserQuizComponent,
    canActivate: [authGuardFn],
  },

  { path: '**', component: HomeComponent, canActivate: [authGuardFn] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
